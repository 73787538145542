import React from 'react'
import { Route } from 'react-router-dom'

import SchoolInfo from './schoolInfo' // 学校信息
import StudentdInfo from './studentdInfo' // 招生信息
import AddStudentInfo from './addStudentInfo' // 新增招生

export default (
  <React.Fragment>
    <Route path='/schoolInfo' component={SchoolInfo} />
    <Route path='/studentsInfo' component={StudentdInfo} />
    <Route path='/addStudentInfo' component={AddStudentInfo} />
  </React.Fragment>
)
