import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Input, Button } from 'antd'
import styles from './login'
import { setStore } from '@/common/utils/localstore'
import md5 from 'md5'
import logo from '@/assets/images/logo.png'
import logoIcon from '@/assets/images/logo_icon.png'
import wechartIcon from '@/assets/images/weChatIcon.png'
import blogIcon from '@/assets/images/blogIcon.png'
import wechat from '@/assets/images/wechat.png'
import blog from '@/assets/images/blog.png'

import Api from '@/common/api'

const FormItem = Form.Item

class Login extends Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
  }

  // 登录按钮
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.login(values)
      }
    })
  }

  login = values => {
    let params = {}
    params['phone'] = values.userName
    params['password'] = md5(values.password)
    params['platform'] = 'web'
    Api.login(params).then(({ res }) => {
      const { success, data } = res
      if (success) {
        const { token, schoolId, schoolName } = data
        setStore('token', token)
        setStore('schoolId', schoolId)
        setStore('schoolName', schoolName)
        this.props.history.push('/')
      }
    })
  }

  goToLink = () => {
    this.props.history.push({ pathname: '/findPassword' })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    return (
      <div className={styles.loginWrap}>
        <div className={`${styles.w1190} ${styles.flex}`}>
          <div className={styles.logoCont}>
            <img src={logo} alt='' />
            <p className={styles.title}>钦家，是一个爱的方式</p>
          </div>
          <div className={styles.loginCont}>
            <div className={styles.loginCon}>
              <div className={styles.loginLogo}>
                <span className={styles.loginTitle}>钦家校园管理平台</span>
              </div>
              <div className={styles.loginBody}>
                <Form onSubmit={this.handleSubmit}>
                  <FormItem>
                    {getFieldDecorator('userName', {
                      rules: [{ required: true, message: '请输入登录账户!' }],
                    })(
                      <Input
                        className={styles.loginInput}
                        prefix={
                          <Icon type='user' style={{ color: 'rgba(0,0,0,0.25)' }} />
                        }
                        placeholder='账号'
                        autoComplete='off'
                      />,
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: '请输入密码!' }],
                    })(
                      <Input
                        className={styles.loginInput}
                        prefix={
                          <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type='password'
                        placeholder='密码'
                      />,
                    )}
                  </FormItem>
                  {/* <Row className={styles.forget}>
                  <a href='javascript:;' onClick={this.goToLink}>
                    忘记密码
                  </a>
                </Row> */}
                  <FormItem>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className={styles.loginbutton}
                    >
                    登录
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.w1190}>
          <div className={styles.otherCont}>
            <div className={styles.leftCont}>
              <p className={styles.logo}>
                <img src={logoIcon} alt='' />
                <span>钦家</span>
              </p>
              <p className={styles.word}>ISUS商务合作</p>
              <p className={styles.word}>4008-950-110</p>
              <p className={styles.time}>(工作日：9:00-18:00)</p>
            </div>
            <div className={styles.rightCont}>
              <p className={styles.aboutUs}>关于我们</p>
              <div className={styles.codes}>
                <div className={styles.codeLeft}>
                  <img className={styles.codesImg} src={wechat} alt='' />
                  <span>
                    <img className={styles.codesIcon} src={wechartIcon} alt='' />
                    官方公众号
                  </span>
                </div>
                <div className={styles.codeRight}>
                  <img className={styles.codesImg} src={blog} alt='' />
                  <span>
                    <img className={styles.codesIcon} src={blogIcon} alt='' />
                    官方微博
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.companyCont}>
            <p>上海钦轩网络科技有限公司</p>
            <p>Shanghai iworld Network Technology Co,Ltd</p>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object,
}

const LoginForm = Form.create()(Login)
export default LoginForm
