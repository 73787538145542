import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Radio, DatePicker } from 'antd'
import moment from 'moment'

const FormItem = Form.Item

class AddStudentModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleConfirm = (e) => {
    const { isEdit, detailInfo } = this.props
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (isEdit) {
          values['studentId'] = detailInfo.studentId
        }
        values['classId'] = detailInfo.classId
        this.props.onOk(values)
      }
    })
  }

  handleCancel = (e) => {
    this.props.onCancel()
  }

  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      },
    }
    const { getFieldDecorator } = this.props.form
    const { isEdit, detailInfo = {} } = this.props
    return (
      <div>
        <Modal
          visible
          maskClosable={false}
          title={isEdit ? '编辑学生' : '添加学生'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='班级' {...formItemLayout}>
                {
                  getFieldDecorator('channelName', {
                    initialValue: detailInfo.channelName,
                  })(
                    <span>{`${detailInfo.gradeName} ${detailInfo.classname}`}</span>
                  )
                }
              </FormItem>
              <FormItem label='学生姓名' {...formItemLayout}>
                {
                  getFieldDecorator('name', {
                    initialValue: detailInfo.name,
                    rules: [
                      { required: true, message: '请输入学生姓名!' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='性别' {...formItemLayout}>
                {
                  getFieldDecorator('sex', {
                    initialValue: detailInfo.sex || '1',
                    rules: [
                      { required: true, message: '请选择性别!' }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value={'1'}>男</Radio>
                      <Radio value={'2'}>女</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>
              <FormItem label='出生日期' {...formItemLayout}>
                {getFieldDecorator('birth', {
                  initialValue: detailInfo.birth ? moment(new Date(detailInfo.birth), 'YYYY-MM-DD') : null,
                })(
                  <DatePicker
                    showTime
                    format='YYYY-MM-DD'
                    placeholder='请选择出生日期'
                  />
                )}
              </FormItem>
              <FormItem label='学号' {...formItemLayout}>
                {
                  getFieldDecorator('no', {
                    initialValue: detailInfo.no,
                    rules: [
                      { required: true, message: '请输入学号!' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='是否走读' {...formItemLayout}>
                {
                  getFieldDecorator('notLive', {
                    initialValue: detailInfo.notLive || '0',
                  })(
                    <Radio.Group>
                      <Radio value={'1'}>是</Radio>
                      <Radio value={'2'}>否</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

AddStudentModalForm.propTypes = {
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
  detailInfo: PropTypes.object
}

const AddStudentModal = Form.create()(AddStudentModalForm)

export default AddStudentModal
