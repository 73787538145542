const classCreate = (ajaxinstance) => {
  const classApi = {}

  // 年级和班级列表接口
  classApi.getGradeList = (postData) => (
    ajaxinstance.post('/attendance/grade/list', postData)
  )
  // 学生列表
  classApi.getStudentList = (postData) => (
    ajaxinstance.post('/attendance/student/list', postData)
  )
  // 更换班主任
  classApi.saveTeacher = (postData) => (
    ajaxinstance.post('/attendance/class/save', postData)
  )
  // 新增学生
  classApi.addStudent = (postData) => (
    ajaxinstance.post('/attendance/student/save', postData)
  )
  // 新增年级
  classApi.addGrade = (postData) => (
    ajaxinstance.post('/attendance/grade/save', postData)
  )
  // 新增班级
  classApi.addClass = (postData) => (
    ajaxinstance.post('/attendance/class/save', postData)
  )
  // 删除学生
  classApi.removeStudent = (postData) => (
    ajaxinstance.post('/attendance/app/teacher/student/change/status', postData)
  )

  // 删除班级
  classApi.deleteClass = (postData) => (
    ajaxinstance.post('/attendance/class/delete', postData)
  )
  // 删除班级
  classApi.deleteGrade = (postData) => (
    ajaxinstance.post('/attendance/grade/delete', postData)
  )
  return classApi
}

export default classCreate
