import React from 'react'
import styles from './home'
import { Button, Card, Col, message, Row, Upload } from 'antd'
import Api from '@/common/api'
import { getStore } from '@/common/utils/localstore'
import XLSX from 'xlsx'

require('./home.scss')
let wb // 读取完成的数据
let rABS = false // 是否将文件读取为二进制字符串
const propsStudent = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text'
  },
  onChange (info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList)
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }
}
const propsTeacher = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text'
  },
  onChange (info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList)
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }
}

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      attendedNum: 0,
      totalNum: 0,
      num: 0,
      school: '上海实验小学',
      studentNum: '0',
      teacherNum: '0',
      attendance: null,
      highTemperatureNo: 0,
      late: 0,
      leave: 0
    }
  }

  componentDidMount () {
    // console.log(IS_ENV)
    this.init()
  }

  init (date) {
    const schoolId = getStore('schoolId')
    const schoolName = getStore('schoolName')
    this.setState({
      school: schoolName
    })
    let schoolParams = { schoolId: schoolId, date: date || null }
    // this.get
    this.getTeacherList(schoolParams)
    this.getSchoolAttendence(schoolParams)
  }

  getSchoolAttendence (params, index) {
    try {
      Api.getSchoolAttendence(params)
        .then(({ res }) => {
          let { data } = res
          console.log(data)
          let highTemperatureNo = data.highTemperatureNo || 0
          let checkedNo = data.checked || 0
          let totalNo = data.total || 0
          let lateNo = data.late || 0
          let leaveNo = data.leave || 0
          // if (data && data.checkCountList && data.checkCountList.length > 0) {
          //   data.checkCountList.map(el => {
          //     // console.log(el)
          //     checkedNo += el.checkedNo
          //     totalNo += el.totalNo
          //     lateNo += el.lateNo
          //     leaveNo += el.leaveNo
          //   })
          // }
          this.setState({
            attendance: data,
            attendedNum: checkedNo,
            num: totalNo,
            late: lateNo,
            highTemperatureNo: highTemperatureNo,
            leave: leaveNo
          })
          // this.handleAttendance(data.checkCountList)
          // console.log(this.state.attendance)
        })
        .catch(e => {
          console.log('school', e)
        })
    } catch (error) {
      console.log('scccc', error)
    }
  }

  getTeacherList (params) {
    Api.queryTeacherList(params).then(({ res }) => {
      // console.log(res)
      this.setState({
        teacherNum: res.total
      })
    })
  }

  // 开始导入
  importf = obj => {
    // console.log(obj)
    if (!obj.files) {
      return
    }
    var f = obj.files[0]
    var reader = new FileReader()
    reader.onload = function (e) {
      var data = e.target.result
      if (rABS) {
        wb = XLSX.read(btoa(this.fixdata(data)), {
          // 手动转化
          type: 'base64'
        })
      } else {
        wb = XLSX.read(data, {
          type: 'binary'
        })
      }
      /**
       * wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
       * wb.Sheets[Sheet名]获取第一个Sheet的数据
       */
      var excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
      document.getElementById('excelContent').innerHTML = JSON.stringify(
        excelJson
      )
    }
    if (rABS) {
      reader.readAsArrayBuffer(f)
    } else {
      reader.readAsBinaryString(f)
    }
  }

  // 文件流转BinaryString
  fixdata = data => {
    var o = '',
      l = 0,
      w = 10240
    for (; l < data.byteLength / w; ++l) {
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(data.slice(l * w, l * w + w))
      )
    }
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
    return o
  }

  beforeUpload (file) {
    let param = new FormData() // 创建form对象
    param.append('file', file)
    Api.importStudent(param).then(({ res }) => {
      // console.log('ipload', res)
    })
  }

  beforeUploadTeacher (file) {
    let param = new FormData() // 创建form对象
    param.append('file', file)
    Api.importTeacher(param).then(({ res }) => {
      if (res.data.success) message.error('Image must smaller than 2MB!')
    })
  }

  gotoAttendance () {
    window.location.replace('#/schoolAttendance')
  }

  render () {
    const { editorState } = this.state
    return (
      <div className={styles.home}>
        <Row gutter={20}>
          <Col span={16}>
            <div className={styles.data}>
              <div className={styles.card}>
                <h1>今日考勤数据</h1>
                <Card
                  title=""
                  className={styles.content}
                  style={{ width: 320 }}
                >
                  <p>考勤学生/总学生</p>
                  <p className={styles.totalNum}>
                    {this.state.attendedNum}/{this.state.num}
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={this.gotoAttendance.bind(this)}
                  >
                    考勤明细 >
                  </a>
                  <div>
										<span className={styles.statistics}>
											<p className={styles.statisticsNum}>
                        {this.state.late}
											</p>
											<p>迟到</p>
										</span>
                    <span className={styles.statistics}>
											<p className={`${styles.statisticsNum} ${styles.grey}`}>
												{this.state.leave}
											</p>
											<p>请假</p>
										</span>
                    <span className={styles.statistics}>
											<p className={`${styles.statisticsNum} ${styles.grey}`}>
												{this.state.highTemperatureNo}
											</p>
											<p>温度异常</p>
										</span>
                  </div>
                </Card>
              </div>
              <div className={styles.card}>
                <h1>常用工具入口</h1>
                <Card style={{ width: 680 }}>
                  <Upload
                    {...propsStudent}
                    className={styles.button}
                    beforeUpload={this.beforeUpload}
                  >
                    <Button>批量导入学生</Button>
                  </Upload>
                  ,
                  <Upload
                    {...propsTeacher}
                    className={styles.button}
                    beforeUpload={this.beforeUploadTeacher}
                  >
                    <Button>批量导入老师</Button>
                  </Upload>
                  ,
                  <Button
                    className={styles.button}
                    onClick={() => {
                      window.location.replace('#/ruleList')
                    }}
                  >
                    调整考勤规则
                  </Button>
                </Card>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <Card title={this.state.school} style={{ marginTop: '50px' }}>
              <p>
                <img src={this.state.studentImg}/> 学生:{this.state.num}
              </p>
              <p>
                <img src={this.state.teacherImg}/>
                老师:{this.state.teacherNum}
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Home
