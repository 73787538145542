import React, { Component } from 'react'
import { Layout, Menu, Icon } from 'antd'
import PropTypes from 'prop-types'
import logoImg from '@/assets/images/logo_icon.png'

import { Link } from 'react-router-dom'
const SubMenu = Menu.SubMenu
const Sider = Layout.Sider

export default class SiderMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userMenus: []
    }
  }

  componentDidMount = () => {};

  handleMenuItem = menuItem => {
    let Obj = { key: '', title: '' }
    Obj.key = menuItem.key
    Obj.title = menuItem.item.props.children
    this.props.onMenu(Obj)
  };

  render () {
    const { collapsed, menus } = this.props
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className='navList'
      >
        {
          !collapsed
            ? <div className='headerLogo'>
              <img src={logoImg} />
              <span>钦家校园管理平台</span>
            </div>
            : <div className='headerLogo1'>
              <img src={logoImg} />
            </div>
        }
        <Menu
          theme='dark'
          defaultSelectedKeys={['1']}
          mode='inline'
          onSelect={this.handleMenuItem}
        >
          <Menu.Item key='/'>
            <Icon type='home' />
            <span>首页</span>
          </Menu.Item>
          {menus.map((item, index) => {
            return (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    <Icon type={item.icon} />
                    <span>{item.title}</span>
                  </span>
                }
              >
                {item.menuList.map(me =>
                  me.menuList.length > 0 ? (
                    <SubMenu key={me.key} title={<span>{me.title}</span>}>
                      {me.menuList.map(child => (
                        <Menu.Item key={child.key}>
                          <Link to={`/${child.key}`}>
                            <span>{child.title}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={me.key}>
                      <Link to={`/${me.key}`}>
                        <span>{me.title}</span>
                      </Link>
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            )
          })}
        </Menu>
      </Sider>
    )
  }
}

SiderMenu.propTypes = {
  onMenu: PropTypes.func,
  collapsed: PropTypes.bool,
  menus: PropTypes.array
}
