import React from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, message, Modal } from 'antd'
import Api from '@/common/api'
import { getStore } from '@/common/utils/localstore'
import { weekStatus } from '@/common/utils/filter'

class ruleList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ruleList: [{ name: '' }, {}],
      value: 1,
      ruleConfigList: [],
      modal: {
        deleteRule: false
      }
    }
    this.columns = [
      {
        title: '考勤年级',
        dataIndex: 'grades',
      },
      {
        title: '考勤时间',
        render: record => {
          let str = ''
          record.times && record.times.forEach((item, index) => {
            str += `<div>
              <p>${item.weeks}</p>
              <p>截止入校时间：${item.inTime} 放学时间：${item.leaveTime}</p>
            </div>`
          })
          return (<div dangerouslySetInnerHTML={{ __html: str }} />)
        }
      },
      {
        title: '操作',
        render: (record) => (
          <span>
            <a style={{ paddingRight: '10px' }} href='javascript:;' onClick={() => this.editData(record.checkRuleId)}>
              编辑
            </a>
            <a href='javascript:;' onClick={() => this.deleteData(record.checkRuleId)}>
              删除
            </a>
          </span>
        )
      }
    ]
  }
  componentDidMount () {
    this.getRuleList()
  }
  editData (data) {
    this.props.history.push(`/ruleConfig?id=${data}`)
  }
  deleteData (data) {
    this.setState({
      modal: {
        deleteRule: true
      },
      deleteData: data
    })
  }
  handleOk = () => {
    const { deleteData } = this.state
    Api.detaleRule({ id: deleteData }).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('删除成功')
        this.getRuleList()
        this.setState({
          modal: {
            deleteRule: false
          }
        })
      }
    })
  }
  handleCancel =() => {
    this.setState({
      modal: {
        deleteRule: false
      }
    })
  }
  getRuleList () {
    let id = getStore('schoolId')
    let params = {
      schoolId: id,
      pageSize: 10,
      pageNo: 1
    }
    Api.getRuleListAll(params).then(({ res }) => {
      let { data } = res
      let arr = []
      data && data.map((item, index) => {
        let obj = {}
        let gradeArr = []
        // 考勤年级
        if (item.grades && item.grades.length > 0) {
          item.grades.map((el, ind) => {
            gradeArr.push(el.gradeName)
          })
          obj.grades = gradeArr.join('、')
        } else {
          obj.grades = ''
        }
        obj.checkRuleId = item.checkRuleId
        // 考勤时间
        let timeArr = []
        if (item.checkTimes && item.checkTimes.length > 0) {
          item.checkTimes.map((el, ind) => {
            let timeObj = {}
            timeObj.weeks = weekStatus(el.time)
            timeObj.inTime = el.inTime
            timeObj.leaveTime = el.leaveTime
            timeArr.push(timeObj)
          })
        }
        obj.times = timeArr
        arr.push(obj)
      })
      this.setState({
        ruleConfigList: arr
      })
    })
  }
  onChange = e => {
    this.setState({
      value: e.target.value * 1
    })
  };
  handleAddRule = () => {
    this.props.history.push({ pathname: '/ruleConfig' })
  }
  render () {
    const { ruleConfigList, modal } = this.state
    return (
      <div>
        <h1>考勤规则</h1>
        <div style={{ margin: '20px 0' }}>
          <Button onClick={this.handleAddRule} type='primary'>添加</Button>
        </div>
        <div>
          <Card>
            <Table
              columns={this.columns}
              dataSource={ruleConfigList}
              id='table'
            />
          </Card>
        </div>
        {
          modal.deleteRule
            ? <Modal
              title='提示'
              visible
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p>{`确认删除?`}</p>
            </Modal> : null
        }
      </div>
    )
  }
}
ruleList.propTypes = {
  history: PropTypes.object
}

export default ruleList
