/**
 * 去掉前后空格
 *
 */
export const trimSpace = str => {
  // let result = ''
  // result = str.replace(/(^\s*)|(\s*$)/g, '')
  // return result
  return (str || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')
}

export const trimAllSpace = str => {
  return (str || '').replace(/[ ]/g, '')
}

/**
 * 校验手机号
 */
export const isPhoneAble = str => {
  let reg = /^[1][2-9][0-9]{9}$/
  if (!reg.test(str)) {
    return false
  } else {
    return true
  }
}
/**
 * 菜单列表
 */
export const menusList = () => {
  const menus = [
    {
      menuId: 1,
      title: '考勤管理',
      icon: 'calendar',
      menuList: [
        {
          menuId: 11,
          title: '全校考勤',
          icon: null,
          menuList: [
            // {
            // 	menuId: 111,
            // 	title: "班级考勤",
            // 	icon: null,
            // 	menuList: [],
            // 	key: "classAttendance"
            // },
            // {
            // 	menuId: 112,
            // 	title: "学生考勤",
            // 	icon: null,
            // 	menuList: [],
            // 	key: "studentAttendance"
            // }
          ],
          key: 'schoolAttendance'
        },
        {
          menuId: 12,
          title: '考勤规则',
          icon: null,
          menuList: [],
          key: 'ruleList'
        }
      ],
      key: 'attendance'
    },
    {
      menuId: 2,
      title: '班级管理',
      icon: 'team',
      menuList: [
        {
          menuId: 21,
          title: '班级管理',
          icon: null,
          menuList: [],
          key: 'classList'
        }
      ],
      key: 'class'
    },
    {
      menuId: 3,
      title: '教师管理',
      icon: 'user',
      menuList: [
        {
          menuId: 31,
          title: '教师管理',
          icon: null,
          menuList: [],
          key: 'teacherList'
        },
        {
          menuId: 32,
          title: '教师添加',
          icon: null,
          menuList: [],
          key: 'addTeacher'
        }
      ],
      key: 'teacher'
    },
    {
      menuId: 4,
      title: '学校信息管理',
      icon: 'shop',
      menuList: [
        {
          menuId: 41,
          title: '学校信息',
          icon: null,
          menuList: [],
          key: 'schoolInfo'
        },
        {
          menuId: 42,
          title: '招生信息',
          icon: null,
          menuList: [],
          key: 'studentsInfo'
        }
      ],
      key: 'school'
    },
    {
      menuId: 5,
      title: '通知管理',
      icon: 'bell',
      menuList: [
        {
          menuId: 51,
          title: '通知列表',
          icon: null,
          menuList: [],
          key: 'noticeList'
        },
        {
          menuId: 52,
          title: '发布通知',
          icon: null,
          menuList: [],
          key: 'addNotice'
        }
      ],
      key: 'notice'
    },
    {
      menuId: 6,
      title: '校园安全',
      icon: 'safety-certificate',
      menuList: [
        // {
        // 	menuId: 61,
        // 	title: "校门安防系统",
        // 	icon: null,
        // 	menuList: [],
        // 	key: "gateSecurity"
        // },
        {
          menuId: 62,
          title: '家长身份识别系统',
          icon: null,
          menuList: [],
          key: 'parentsIdentity'
        }
      ],
      key: 'security'
    }
  ]
  return menus
}
/**
 * 时间戳转时间
 */
export const getData = (str, birth) => {
  if (str) {
    if (birth) {
      let arr = new Date(parseInt(str))
        .toLocaleString()
        .replace(/年|月/g, '-')
        .replace(/日/g, ' ')
        .split(' ')
      return arr[0]
    } else {
      return new Date(parseInt(str))
        .toLocaleString()
        .replace(/年|月/g, '-')
        .replace(/日/g, ' ')
    }
  } else {
    return ''
  }
}

/**
 * 招生列表，状态
 */
export const studentStatus = val => {
  if (val === '1') {
    return '招生中，未生效'
  } else if (val === '2') {
    return '招生中，已生效'
  } else if (val === '3') {
    return '未招生，未生效'
  } else if (val === '4') {
    return '未招生，已生效'
  } else if (val === '5') {
    return '已结束，已生效'
  } else if (val === '6') {
    return '已结束，未生效'
  }
}
/**
 * 招生列表，星期转换
 */
export const weekStatus = val => {
  if (!val) {
    return ''
  } else {
    let arr = val.split(',')
    let newArr = arr.map(el => strToWeek(el))
    return newArr.join('、')
  }
}
export const strToWeek = val => {
  if (!val) return
  if (val === '1') {
    return '周一'
  } else if (val === '2') {
    return '周二'
  } else if (val === '3') {
    return '周三'
  } else if (val === '4') {
    return '周四'
  } else if (val === '5') {
    return '周五'
  } else if (val === '6') {
    return '周六'
  } else if (val === '7') {
    return '周日'
  }
}

export const dateFormatTime = (date, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }
  let o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  }
  let week = {
    '0': '\u65e5',
    '1': '\u4e00',
    '2': '\u4e8c',
    '3': '\u4e09',
    '4': '\u56db',
    '5': '\u4e94',
    '6': '\u516d'
  }
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
