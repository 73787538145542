const noticeCreate = (ajaxinstance) => {
  const notice = {}

  // 添加公告
  notice.addNotice = (postData) => (
    ajaxinstance.post('/attendance/school/msg/add', postData)
  )
  // 公告列表
  notice.noticeList = (postData) => (
    ajaxinstance.post('/attendance/school/msg/pc/list', postData)
  )

  return notice
}

export default noticeCreate
