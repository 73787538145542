import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, Row, Col, Button, message, Modal } from 'antd'
import Api from '@/common/api'
import md5 from 'md5'
import { getStore } from '@/common/utils/localstore'

const FormItem = Form.Item
class ChangePassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount = () => {
  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码保持一致!')
    } else {
      callback()
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  };
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let params1 = {
          schoolId: getStore('schoolId'),
          password: md5(values.oldPassword)
        }
        Api.checkOldPassword(params1).then(({ res }) => {
          const { success } = res
          if (success) {
            let params2 = {
              schoolId: getStore('schoolId'),
              password: md5(values.password)
            }
            Api.changePassword(params2).then(({ res }) => {
              const { success } = res
              if (success) {
                // 重新登录
                Modal.info({
                  title: '提示',
                  content: (
                    <div>
                      <p>密码修改成功！</p>
                    </div>
                  ),
                  okText: '重新登录',
                  onOk: () => {
                    this.props.history.push('/login')
                  },
                })
              }
            })
          }
        })
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 4
        }
      }
    }
    return (
      <div style={{ width: '600px', margin: '60px auto' }}>
        <Form>
          <FormItem className='firstFormItem'
            {...formItemLayout}
            label='原密码'
          >
            {getFieldDecorator('oldPassword', {
              rules: [{
                required: true, message: '请输入新密码!',
              }, {
                minLength: 6, message: '至少6位'
              }]
            })(
              <div className='common_form'>
                <Input type='password' placeholder='请输入新密码' />
              </div>
            )}
          </FormItem>
          <FormItem className='firstFormItem'
            {...formItemLayout}
            label='设置新密码'
          >
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: '请输入新密码!',
              }, {
                validator: this.validateToNextPassword
              }, {
                minLength: 6, message: '至少6位'
              }]
            })(
              <div className='common_form'>
                <Input type='password' placeholder='请输入新密码' />
              </div>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='确认新密码'
          >
            <Row>
              <Col>
                {getFieldDecorator('passwordAgain', {
                  rules: [{ required: true, message: '请输入验证码!' },
                    { validator: this.compareToFirstPassword }, ]
                })(
                  <Input type='password' placeholder='两次密码保持一致' />
                )}
              </Col>
            </Row>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <p>密码由6~20位字母（区分大小写）、数字或符号组成</p>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>提交</Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

ChangePassword.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object
}

const ChangePasswordForm = Form.create()(ChangePassword)
export default ChangePasswordForm
