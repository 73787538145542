import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, DatePicker, Form, Input, Button } from 'antd'
import moment from 'moment'
import styles from '@/routes/attendance/schoolAttendance/schoolAttendance'
const { RangePicker } = DatePicker

const FormItem = Form.Item

class AddGradeModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleConfirm = e => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // if (isEdit) {
        //   values['studentId'] = detailInfo.studentId
        // }
        // values['classId'] = detailInfo.classId
        // this.props.onOk(values)
      }
    })
  }

  handleCancel = e => {
    this.props.onCancel()
  }
  onChange (date, dateString) {
    console.log(date)
    console.log(dateString)
  }
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
      },
    }

    const style = {
      width:472
    }

    const { getFieldDecorator } = this.props.form
    return (
      <div >
        <Modal
          visible
          maskClosable={false}
          title={'导出全校点名册'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div >
            <RangePicker
              style={style}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
              showTime
              format='YYYY/MM/DD HH:mm:ss'
              onChange={this.onChange.bind(this)}
            />
            {/* <Form>
              <FormItem label="班级名称" {...formItemLayout}>
                {' '}
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '请输入班级名称!',
                    },
                  ],
                })(<Input placeholder="请输入..." autoComplete="off" />)}{' '}
              </FormItem>
            </Form> */}
          </div>
        </Modal>
      </div>
    )
  }
}

AddGradeModalForm.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
}

const OutportModal = Form.create()(AddGradeModalForm)

export default OutportModal
