import { Component } from 'react'
import { setStore } from '@/common/utils/localstore'

class Sso extends Component {
  componentDidMount () {

    console.log('http://192.168.1.190:8000/crm/school_manager')
    // 获取URl参数
    const GetRequest = (urlStr) => {
      let url
      if (typeof urlStr !== 'undefined') {
        url = '?' + urlStr.split('?')[1]
      } else {
        return
      }
      let theRequest = {}
      if (url.indexOf('?') !== -1) {
        let str = url.substr(1)
        let strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[ strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])
        }
      }
      return theRequest
    }
    const url = window.location.href
    const data = GetRequest(url)
    const token = data['token'] || ''
    const schoolName = data['schoolName'] || ''
    const schoolId = data['schoolId'] || ''
    if (token) {
      setStore('token', token)
      setStore('schoolId', schoolId)
      setStore('schoolName', schoolName)
      window.location.replace('/')
    } else {
      return false
    }
  }

  render () {
    return ''
  }
}

export default Sso
