import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class AddClassModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleConfirm = (e) => {
    const { isEdit, detailInfo } = this.props
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (isEdit) {
          values['classId'] = detailInfo.classId
        }
        values['schoolId'] = detailInfo.schoolId
        values['gradeId'] = detailInfo.gradeId
        this.props.onOk(values)
      }
    })
  }

  handleCancel = (e) => {
    this.props.onCancel()
  }

  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      },
    }
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    return (
      <div>
        <Modal
          visible
          maskClosable={false}
          title={'添加班级'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='班级名称' {...formItemLayout}>
                {
                  getFieldDecorator('name', {
                    initialValue: detailInfo.name || '',
                    rules: [
                      { required: true, message: '请输入班级名称!' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='排序' {...formItemLayout}>
                {
                  getFieldDecorator('sort', {
                    initialValue: detailInfo.sort || '',
                    rules: [
                      { required: true, message: '请输入排序!' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

AddClassModalForm.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
  detailInfo: PropTypes.object,
  isEdit: PropTypes.bool
}

const AddClassModal = Form.create()(AddClassModalForm)

export default AddClassModal
