import React from 'react'
import { Route } from 'react-router-dom'

import ClassAttendance from './classAttendance' // 班级考勤
import StudentAttendance from './studentAttendance' // 学生考勤记录
import SchoolAttendance from './schoolAttendance'

export default (
  <React.Fragment>
    <Route path="/schoolAttendance" component={SchoolAttendance}/>
    <Route path="/classAttendance" component={ClassAttendance}/>
    <Route path="/studentAttendance" component={StudentAttendance}/>
  </React.Fragment>
)
