import React from "react";
// import styles from "./index";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import Api from "@/common/api";
// import { Card, Button, Select, Row, Col, Modal, DatePicker, Table } from "antd";

export default class gateSecurity extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null
		};
	}

	componentDidMount() {
		// console.log(this.state.info);
		this.initData();
	}
	initData(date) {}
	render() {
		return <div>asssssssssssssssssssssss</div>;
	}
}
