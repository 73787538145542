import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, Button, message } from 'antd'

import Api from '@/common/api'
import { getStore } from '@/common/utils/localstore'

const FormItem = Form.Item
const { TextArea } = Input

class AddNoticeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }
  handlePublish = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleAddNotice(values)
      }
    })
  }
  handleAddNotice = (params) => {
    params['schoolId'] = getStore('schoolId')
    Api.addNotice(params).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('发布成功', 1, () => {
          this.props.history.push('/noticeList')
        })
      }
    })
  }
  handlePublishTime = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 12
      },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <div style={{ paddingTop: '20px' }}>
        <Form>
          <FormItem label='通知标题' {...formItemLayout}>
            {
              getFieldDecorator('title', {
                rules: [
                  { required: true, message: '请输入通知标题!' }
                ]
              })(
                <Input placeholder='请输入通知标题，限20个字' maxLength={20} autoComplete='off' />
              )
            }
          </FormItem>
          <FormItem label='发布者' {...formItemLayout}>
            {
              getFieldDecorator('publisher', {
                rules: [
                  { required: true, message: '请输入发布者!' }
                ]
              })(
                <Input placeholder='请输入发布者，限10个字' maxLength={10} autoComplete='off' />
              )
            }
          </FormItem>
          <FormItem label='通知内容' {...formItemLayout}>
            {
              getFieldDecorator('content', {
                rules: [
                  { required: true, message: '请输入通知内容!' }
                ]
              })(
                <TextArea rows={12} maxLength={500} placeholder='请输入通知内容，限500字' />
              )
            }
          </FormItem>
        </Form>
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <Button type='primary' onClick={this.handlePublish} style={{ marginRight: '20px' }}>
            发布
          </Button>
          {/* <Button onClick={this.handlePublishTime}>
            定时发布
          </Button> */}
        </div>
      </div>
    )
  }
}
AddNoticeForm.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object
}

const AddNotice = Form.create()(AddNoticeForm)

export default AddNotice
