import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, TimePicker, Checkbox } from 'antd'
import moment from 'moment'
// const { MonthPicker, RangePicker } = DatePicker
const format = 'HH:mm'
const FormItem = Form.Item

class AddGradeModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      classList: [
        { label: '周一', value: '1' },
        { label: '周二', value: '2' },
        { label: '周三', value: '3' },
        { label: '周四', value: '4' },
        { label: '周五', value: '5' },
        { label: '周六', value: '6' },
        { label: '周日', value: '7' },
      ],
      chooseDate: '',
    }
  }
  handleInTimeChange = (time, timeString) => {
    this.setState({
      inTime: timeString
    })
  }
  handleLeaveTimeChange = (time, timeString) => {
    this.setState({
      leaveTime: timeString
    })
  }
  handleConfirm = e => {
    const { inTime, leaveTime } = this.state
    const { timeDetail = {} } = this.props
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (timeDetail.id || timeDetail.id === 0) {
          values.id = timeDetail.id
        }
        values.inTime = inTime || timeDetail.inTime
        values.leaveTime = leaveTime || timeDetail.leaveTime
        this.props.onOk(values)
      }
    })
  }

  handleCancel = e => {
    this.props.onCancel()
  }
  onChange = (checkedValues) => {
    this.setState({
      chooseDate: checkedValues.join(','),
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
      },
    }
    const { getFieldDecorator } = this.props.form
    const { classList } = this.state
    const { timeDetail = {} } = this.props
    return (
      <div>
        <Modal
          visible
          maskClosable={false}
          title={'添加考勤时间'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='考勤时间' {...formItemLayout}>
                {getFieldDecorator('time', {
                  initialValue: timeDetail.time ? timeDetail.time.split(',') : [],
                  rules: [{ required: true, message: '请填写时间!' }],
                })(
                  <Checkbox.Group
                    style={{ width: '100%' }}
                    options={classList}
                    onChange={this.onChange}
                  >
                  </Checkbox.Group>
                )}
              </FormItem>
              <FormItem label='入校截止时间' {...formItemLayout}>
                {getFieldDecorator('inTime', {
                  initialValue: timeDetail.inTime ? moment(timeDetail.inTime, format) : '',
                  rules: [{ required: true, message: '请填写时间!' }],
                })(
                  <TimePicker
                    format={format}
                    placeholder='入校截止时间'
                    onChange={this.handleInTimeChange}
                  />
                )}
              </FormItem>
              <FormItem label='放学时间' {...formItemLayout}>
                {getFieldDecorator('leaveTime', {
                  initialValue: timeDetail.leaveTime ? moment(timeDetail.leaveTime, format) : '',
                  rules: [{ required: true, message: '请填写时间!' }],
                })(
                  <TimePicker
                    format={format}
                    placeholder='放学时间'
                    onChange={this.handleLeaveTimeChange}
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

AddGradeModalForm.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
  timeDetail: PropTypes.object
}

const AddGradeModal = Form.create()(AddGradeModalForm)

export default AddGradeModal
