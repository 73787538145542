import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, message } from 'antd'

import DataTable from '@/components/data-table'
import Api from '@/common/api'
import { getStore } from '@/common/utils/localstore'
import { getData, studentStatus } from '@/common/utils/filter'

export default class StudentdInfo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: { total: 0, pageNum: 1, pageSize: 10 },
      tableData:  [],
    }
    // 订单列表表头 设置
    this.columns = [
      {
        title: '招生日期',
        render: (record) => (
          <div>
            {`${getData(record.startTime, true)}~${getData(record.endTime, true)}`}
          </div>
        )
      },
      {
        title: '招生对象',
        dataIndex: 'target'
      },
      {
        title: '招生人数',
        dataIndex: 'no'
      },
      {
        title: '状态',
        render: (record) => (
          <div>
            {studentStatus(record.status)}
          </div>
        )
      },
      {
        title: '操作',
        render: (record) => (
          <div>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleEdit(record)}>
              编辑
            </a>
            {/* <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleDelete(record)}>
              删除
            </a> */}
            {/* <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleDelete(record)}>
              停用
            </a> */}
          </div>
        )
      }
    ]
  }

  componentDidMount = () => {
    this.enrollmentList()
  }
  // 招生列表
  enrollmentList = () => {
    const { pages } = this.state
    let params = {
      schoolId: getStore('schoolId'),
      pageNo: pages.pageNum,
      pageSize: pages.pageSize
    }
    Api.enrollmentList(params).then(({ res }) => {
      const { data, total, pageNum, pageSize } = res
      this.setState({
        tableData: data,
        pages: {
          total,
          pageNum,
          pageSize
        }
      })
    })
  }

  // 编辑
  handleEdit = (record) => {
    this.props.history.push(`/addStudentInfo?id=${record.enrollmentId}`)
  }

  // 删除
  handleDelete = (record) => {
    Api.enrollmentDelete({ id: record.enrollmentId }).then(({ res }) => {
      const { success } = res
      const _message = res.message
      if (success) {
        message.success('删除成功！')
        this.enrollmentList()
      } else {
        message.error(_message)
      }
    })
  }

  // 翻页
  pageChange = (obj) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNum: obj.current,
        pageSize: obj.pageSize
      }
    }, () => {
      this.enrollmentList()
    })
  }

  handleCreate = () => {
    this.props.history.push('/addStudentInfo')
  }

  render () {
    const { pages, tableData } = this.state
    return (
      <div>
        <div style={{ padding: '20px' }}>
          <Button type='primary' onClick={this.handleCreate}>新增</Button>
        </div>
        <div>
          <DataTable
            rowKey='id'
            columns={this.columns}
            list={tableData}
            total={pages.total}
            pageSize={pages.pageSize}
            page={pages.pageNum}
            onChange={this.pageChange}
          />
        </div>
      </div>
    )
  }
}

StudentdInfo.propTypes = {
  history: PropTypes.object.isRequired
}
