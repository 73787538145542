const schoolInfoCreate = ajaxinstance => {
  const schoolAPI = {}

  // 学校考勤
  schoolAPI.getEditInfo = data =>
    ajaxinstance.post('/attendance/school/edit', data)
  // 学校考勤
  schoolAPI.uploadFile = data => ajaxinstance.post('/user/upload/file', data)

  // 招生
  schoolAPI.enrollmentList = data =>
    ajaxinstance.post('/attendance/enrollment/list', data)
  // 新增、编辑招生计划
  schoolAPI.enrollmentSave = data =>
    ajaxinstance.post('/attendance/enrollment/save', data)

  // 获取招生计划详情
  schoolAPI.enrollmentDetail = data =>
    ajaxinstance.post('/attendance/enrollment/get', data)
  // 删除招生计划
  schoolAPI.enrollmentDelete = data =>
    ajaxinstance.post('/attendance/enrollment/delete', data)

  // 获取学校信息
  schoolAPI.querySchoolInfo = data =>
    ajaxinstance.post('/attendance/school/get', data)

  // 导出全校点名册
  schoolAPI.exportStudentStatus = data =>
    ajaxinstance.post('/attendance/exportStudentStatus',data)

  return schoolAPI
}

export default schoolInfoCreate
