import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Collapse } from 'antd'

import { getStore } from '@/common/utils/localstore'
import { getData } from '@/common/utils/filter'
import Api from '@/common/api'

const Panel = Collapse.Panel

export default class NoticeList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: { total: 0, pageNum: 1, pageSize: 10 },
      query: {

      }, // 搜索条件
      listData: []
    }
  }

  componentDidMount () {
    this.handleQueryNoticeList()
  }
  handleQueryNoticeList = () => {
    const { pages } = this.state
    let params = {
      createUser: getStore('schoolId'),
      pageNo: pages.pageNum,
      pageSize: 9999
    }
    Api.noticeList(params).then(({ res }) => {
      const { data, total = 0 } = res
      this.setState({
        listData: data || [],
        pages: {
          ...pages,
          total
        }
      })
    })
  }
  // 翻页
  pageChange = (obj) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNum: obj.current,
        pageSize: obj.pageSize
      }
    }, () => {
      this.handleQueryNoticeList()
    })
  }

  setHeaderCont = (item) => {
    return (
      <div style={{ height: '20px' }}>
        <span
          style={{ float: 'left', marginRight: '20px' }}
        >{item.title}</span>
        <span
          style={{ float: 'left', color: '#999' }}
        >{item.createName}</span>
        <span
          style={{ float: 'right' }}
        >{getData(item.createTime)}</span>
      </div>
    )
  }

  render () {
    const { pages, listData } = this.state
    return (
      <div style={{ padding: '20px' }}>
        <div>
          <List
            itemLayout='vertical'
            size='large'
            pagination={{
              onChange: page => {
                // this.pageChange(page)
              },
              pageSize: pages.pageSize,
            }}
            dataSource={listData}
            renderItem={item => (
              <List.Item
                key={item.name}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIconPosition={'right'}
                >
                  <Panel
                    style={{ borderBottom: 0 }}
                    header={this.setHeaderCont(item)}
                    key='1'
                  >
                    {item.content}
                  </Panel>
                </Collapse>
              </List.Item>
            )}
          />
        </div>
      </div>
    )
  }
}
