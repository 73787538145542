import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

import DataTable from '@/components/data-table'
import SearchForm from '@/components/search-form'

export default class ClassList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: { total: 0, pageNum: 1, pageSize: 20 },
      tableData:  [],
      query: {

      }, // 搜索条件
    }
    // 订单列表表头 设置
    this.columns = [
      {
        title: '班级管理序号',
        width: 150,
        dataIndex: 'orderNo'
      },
      {
        title: '老师',
        width: 150,
        dataIndex: 'thirdpartyOrderNo'
      },
      {
        title: '手机号（账号）',
        width: 110,
        dataIndex: 'orderTime'
      },
      {
        title: '班级',
        width: 100,
        dataIndex: 'originalAmount'
      },
      {
        title: '操作',
        width: 150,
        render: (record) => (
          <div>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleEdit(record)}>
              编辑
            </a>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleDelete(record)}>
              删除
            </a>
          </div>
        )
      }
    ]
  }

  componentDidMount = () => {
  }

  // 点击搜索
  handleSearch = (formdata = {}) => {
    const { pages } = this.state
    let _provinces = ''
    let _city = ''

    let _channelId = ''

    if (formdata['cityArr'] && formdata['cityArr'].length > 0) {
      _provinces = formdata.cityArr[0]
      _city = formdata.cityArr[1]
    }

    if (formdata['channelId'] && formdata['channelId'].length > 0) {
      _channelId = formdata.channelId[1]
    }

    this.setState({
      query: {
        ...formdata,
        provinces: _provinces,
        city: _city,
        channelId: _channelId
      },
      pages: {
        ...pages,
        pageNum: 1,
        pageSize: 20
      },
      orderNoFromSampleList:''// 清空样本列表传过来的url参数
    }, () => {
      this.getOrderList()
    })
  }

  // 编辑
  handleEdit = (record) => {
    this.props.history.push({ pathname: '/orderEdit', state: { ...record } })
  }

  // 翻页
  pageChange = (obj) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNum: obj.current,
        pageSize: obj.pageSize
      }
    }, () => {
      this.getOrderList()
    })
  }

  render () {
    const { pages, tableData } = this.state
    const _items = [
      <Input placeholder='请输入...' autoFocus style={{ width: '300px' }}
        // onChange={e => this.onChangeBarCode(e.target.value)}
      />
    ]
    return (
      <div>
        <div style={{ paddingTop: '15px' }}>
          {/* <SearchForm
            items={_items}
            // dataBtns={_btns}
            onSearch={this.handleSearch}
          /> */}
        </div>
        <div>
          <DataTable
            rowKey='id'
            columns={this.columns}
            list={tableData}
            total={pages.total}
            pageSize={pages.pageSize}
            page={pages.pageNum}
            onChange={this.pageChange}
          />
        </div>
      </div>
    )
  }
}

ClassList.propTypes = {
  history: PropTypes.object.isRequired
}
