import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  List,
  Typography,
  Checkbox,
  Radio,
  Row,
  Col,
  message
} from 'antd'
import styles from './ruleConfig.scss'
import Api from '@/common/api'
import { getParams } from '@/common/utils/rowkey'
import { getStore } from '@/common/utils/localstore'
import { weekStatus, getData } from '@/common/utils/filter'
import AddTime from './addAttendanceTime'
import AddExcludeTime from './addExcludeTimes'

const { Group } = Radio
class ruleConfig extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '1',
      data: ['校运动会 2018年3月10日', '暑假 2018年6月15日-2018年8月19日'],
      grades: [],
      timeArr: [],
      useHoliday: null,
      exclueList: [],
      exclueArr: [],
      AddTimeFlag: false,
      AddExcludeTimeFlag: false,
      gradeList: [],
      gradesArr: [],
      ruleDetail: {},
      timeDetail: {}
    }
  }
  onChange = e => {
    this.setState({
      value: e.target.value * 1
    })
  };
  componentDidMount () {
    this.handleGetGradeList()
  }
  init =() => {
    const { id } = getParams()
    if (id) {
      let params = {
        id
      }
      Api.getRuleList(params).then(({ res }) => {
        const { data } = res
        let gradesArr = []
        data.grades.map((item, index) => {
          gradesArr.push(item.gradeId)
        })
        let exclueList = []
        data.excludeTimes && data.excludeTimes.map((item, index) => {
          let str = item.reason + ' ' + getData(item.startDate, 'birth') + '——' + getData(item.endDate, 'birth')
          str = str.replace(/\//g, '-')
          exclueList.push(str)
        })

        let timeArr = data.checkTimes
        this.setState({
          gradesArr,
          grades: gradesArr,
          timeArr,
          ruleDetail: data,
          useHoliday: data.useHoliday === '1',
          value: data.effectType,
          exclueList,
          exclueArr: data.excludeTimes
        })
      })
    }
  }
  handleGetGradeList = () => {
    let params = {
      schoolId: getStore('schoolId')
    }
    Api.getGradeList(params).then(({ res }) => {
      const { data } = res
      data.forEach((item, index) => {
        item.label = item.name
        item.value = item.gradeId
      })
      this.setState({
        gradeList: data
      }, () => {
        this.init()
      })
    })
  }
  syncHoliday = (e) => {
    this.setState({
      useHoliday: e.target.checked
    })
  }
  showTime = (obj, ind) => {
    if (ind === 0 || ind) {
      obj.id = ind
    }
    this.setState({
      AddTimeFlag: true,
      timeDetail: obj
    })
  }
  handleTime = data => {
    let timeList = this.state.timeArr
    let arr = data.time
    let str = ''
    arr.forEach(el => {
      switch (el * 1) {
        case 1:
          str += '周一、'
          break
        case 2:
          str += '周二、'
          break
        case 3:
          str += '周三、'
          break
        case 4:
          str += '周四、'
          break
        case 5:
          str += '周五、'
          break
        case 6:
          str += '周六、'
          break
        case 7:
          str += '周日、'
          break
        default:
          break
      }
    })
    str = str.substring(0, str.length - 1)
    if (data.id || data.id === 0) {
      timeList[data.id].time = data.time.join(',')
      timeList[data.id].inTime = data.inTime
      timeList[data.id].leaveTime = data.leaveTime
    } else {
      timeList.push({
        type: '1', // 时间类型 1周 2天
        timeStr: str,
        time: data.time.join(','),
        inTime: data.inTime,
        leaveTime: data.leaveTime
      })
    }
    this.setState({
      timeArr: timeList,
      AddTimeFlag: false
    })
  };
  showExclude = () => {
    this.setState({
      AddExcludeTimeFlag: true
    })
  }
  handleExclude = data => {
    const { exclueList, exclueArr } = this.state
    let arr = data.time
    let str = data.reason + ' ' + arr[0] + '——' + arr[1]
    str = str.replace(/\//g, '-')
    exclueList.push(str)
    exclueArr.push({ reason: data.reason, startDate: arr[0], endDate: arr[1] })
    this.setState({
      AddExcludeTimeFlag: false,
      exclueList: exclueList,
      exclueArr: exclueArr
    })
  };
  handleCancel = () => {
    this.setState({
      AddTimeFlag: false,
      AddExcludeTimeFlag: false
    })
  }
  saveData =() => {
    const { grades, useHoliday, value, timeArr, exclueArr } = this.state
    const { id } = getParams()
    let params = {}
    let gradesArr = []
    grades.forEach(el => {
      gradesArr.push({ gradeId: el })
    })
    if (!timeArr.length) {
      message.error('请填写考勤时间')
      return false
    }
    params.checkRuleId = id
    params.useHoliday = useHoliday ? 1 : 0
    params.effectType = value === 1 ? 1 : 0
    params.grades = gradesArr
    params.checkTimes = timeArr
    params.excludeTimes = exclueArr
    params.schoolId = getStore('schoolId')
    Api.getRuleSave(params).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('添加成功', 2, () => {
          this.props.history.push('/ruleList')
        })
      }
    })
  }
  handleGrades = (values) => {
    this.setState({
      grades: values
    })
  }
  handleTimeDelete = (ind) => {
    const { timeArr } = this.state
    let newArr = []
    newArr = timeArr.filter((item, index) => index !== ind)
    this.setState({
      timeArr: newArr
    })
  }
  render () {
    let { AddTimeFlag, timeArr, AddExcludeTimeFlag, exclueList,
      gradeList, grades, useHoliday, value, timeDetail } = this.state
    return (
      <div style={{ padding: '20px' }}>
        <h1>考勤设置</h1>
        <div style={{ paddingTop: '20px' }}>
          <Row style={{ paddingTop: '20px' }} className={`${styles.totalGrade}`}>
            <Col className={`${styles.title}`} span={2}>
              考勤年级：
            </Col>
            <Col className={`${styles.content}`} span={18}>
              <Checkbox.Group value={grades}
                options={gradeList}
                style={{ width: '100%' }} onChange={this.handleGrades}>
              </Checkbox.Group>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col className={styles.label} span={2}>
              考勤时间
            </Col>
            <Col span={2}>
              {' '}
              <Button onClick={() => this.showTime()}>添加</Button>
            </Col>
          </Row>

          {timeArr.map((el, ind) => {
            return (
              <Row style={{ marginTop: '10px', border: '1px solid #ccc', padding: '10px' }}>
                <Col span={6}>{el.timeStr || weekStatus(el.time)}</Col>
                <Col span={4}>入校截止时间：{el.inTime}</Col>
                <Col span={4}>放学时间：{el.leaveTime}</Col>
                <Col span={4}>
                  <a onClick={() => this.showTime(el, ind)} href='javascript:;'>编辑</a>
                  <a onClick={() => this.handleTimeDelete(ind)}
                    style={{ marginLeft: '20px' }} href='javascript:;'>删除</a>
                </Col>
              </Row>
            )
          })}

          <div style={{ paddingTop: '20px' }} className={styles.borderBottom}>
            <div className={styles.label}>节假日（不考勤日期）</div>
            <div className={styles.label}>
              <Checkbox
                checked={useHoliday}
                onChange={() => this.syncHoliday(event)}>
                同步中国节假日
              </Checkbox>
            </div>
            <div style={{ marginTop: '20px' }}>
              {exclueList.length > 0 ? (
                <List
                  bordered
                  dataSource={exclueList}
                  renderItem={item => (
                    <List.Item>
                      <Typography.Text mark /> {item}
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
            <div>
              <Button
                onClick={this.showExclude}
                className={styles.addBtn}
              >
                添加
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: '20px' }} className={styles.effectTime}>
            <span>生效时间</span>
            <Group
              onChange={() => this.onChange(event)}
            >
              <Radio style={{ marginLeft: '20px' }} value={'1'} checked={value === '1'}>
                明日生效
              </Radio>
              <Radio value={'0'} checked={value === '0'}>
                立即生效
              </Radio>
            </Group>
          </div>
        </div>
        <Button
          type={'primary'}
          className={styles.btn}
          onClick={this.saveData}
        >
          保存
        </Button>
        {AddTimeFlag ? (
          <AddTime
            timeDetail={timeDetail}
            onOk={this.handleTime}
            onCancel={this.handleCancel}
          />
        ) : null}
        {AddExcludeTimeFlag ? (
          <AddExcludeTime
            onOk={this.handleExclude}
            onCancel={this.handleCancel}
          />
        ) : null}
      </div>
    )
  }
}
ruleConfig.propTypes = {
  history: PropTypes.object
}
export default ruleConfig
