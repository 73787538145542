import React from 'react'
import { Route } from 'react-router-dom'

import ChangePassword from './changePassword' // 修改密码
import Message from './message' // 信箱

export default (
  <React.Fragment>
    <Route path='/changePassword' component={ChangePassword} />
    <Route path='/message' component={Message} />
  </React.Fragment>
)
