const teacherCreate = (ajaxinstance) => {
  const teacher = {}

  // 获取教师列表
  teacher.queryTeacherList = (postData) => (
    ajaxinstance.post('attendance/teacher/list', postData)
  )

  // 批量导入教师
  teacher.teacherImport = (postData) => (
    ajaxinstance.post('teacher/import', postData)
  )

  // 获取教师信息
  teacher.getTeacherInfo = (postData) => (
    ajaxinstance.post('attendance/teacher/get', postData)
  )

  // 修改教师信息
  teacher.saveTeacherInfo = (postData) => (
    ajaxinstance.post('attendance/teacher/save', postData)
  )

  // 删除老师
  teacher.deleteTeacher = (postData) => (
    ajaxinstance.post('attendance/teacher/delete', postData)
  )

  return teacher
}

export default teacherCreate
