import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Spin } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getStore } from '@/common/utils/localstore'
import { getParams } from '@/common/app'
import { menusList } from '@/common/utils/filter'
import Headerbar from '@/components/header-bar'
import SiderMenu from '@/components/sider-menu'

const { Content, Footer } = Layout
class Router extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allMenus: menusList(), // 所有的菜单
      collapsed: false, // 侧菜单是否缩起
      curMenu: {
        // 当前菜单
        key: '',
        title: ''
      }
    }
  }

  componentWillMount () {
    this.requireLogin()
  }
  // 验证登录
  requireLogin = () => {
    // 判断当前url code是否存在
    // 成功： 存储token
    // 失败： 跳转到登录
    const obj = getParams()
    if (obj.code) {
      return
    }
    if (!getStore('token')) {
      this.props.history.push('/login')
    }
  };

  // 缩放菜单栏
onMenuCollapsed = collapsed => {
  let coll = collapsed
  this.setState({
    collapsed: coll
  })
};

// 点击菜单栏
goPage = menuItem => {
  this.setState({
    curMenu: {
      key: menuItem.key,
      title: menuItem.title
    }
  })
  this.props.history.push(menuItem.key)
};

render () {
  const { collapsed, curMenu, allMenus } = this.state
  const { main, children } = this.props
  return (
    <Spin size='large' spinning={main.loading} style={{ position: 'fixed' }}>
      <Layout style={{ minHeight: '100vh' }}>
        <div>
          <SiderMenu
            collapsed={collapsed}
            onMenu={this.goPage}
            menus={allMenus}
          />
        </div>
        <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Content style={{ margin: '0 10px' }}>
            <div>
              <Headerbar
                onCollapsed={this.onMenuCollapsed}
                curMenu={curMenu}
                history={this.props.history}
              />
              <div
                style={{
                  background: '#fff',
                  minHeight: '100vh',
                  border: '1px solid transparent'
                }}
              >
                {children}
              </div>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }} />
        </Layout>
      </Layout>
    </Spin>
  )
}
}

Router.propTypes = {
  children: PropTypes.object,
  main: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(connect(state => ({ main: state.main }))(Router))
