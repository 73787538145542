import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Home from './home'
import Router from './router'
// 页面模块
import Teacher from './teacher'
import Login from './login'
import Class from './class'
import School from './school'
import Attendance from './attendance'
import Sso from './sso'
import RuleConfig from './rule'
import UserInfo from './userInfo'
import Notice from './notice'
import FindPassword from './findPassword'
import GuidePage from './guidePage'
import CampusSecurity from './campusSecurity'

const routes = () => (
  <HashRouter>
    <Switch>
      <Route path="/login" component={Login}/>
      <Route path="/sso" component={Sso}/>
      <Route path="/findPassword" component={FindPassword}/>
      <Route path="/guidePage" component={GuidePage}/>
      <Route
        path="/"
        component={({ match }) => {
          return (
            <Router>
              <div>
                <Route exact path={match.url} component={Home}/>
                {Attendance}
                {RuleConfig}
                {Teacher}
                {Class}
                {School}
                {UserInfo}
                {Notice}
                {CampusSecurity}
              </div>
            </Router>
          )
        }}
      />
    </Switch>
  </HashRouter>
)

export default routes
