import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, message, Modal, Button, Upload } from 'antd'
import Api from '@/common/api'
import config from '@/common/utils/config'
import { getStore } from '@/common/utils/localstore'
import DataTable from '@/components/data-table'

const { host } = config

export default class TeacherList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: { total: 0, pageNum: 1, pageSize: 10 },
      tableData:  [],
      query: {

      }, // 搜索条件
      modal: {
        deleteTeacher: false
      },
      teacherObj: {}
    }
    // 订单列表表头 设置
    this.columns = [
      {
        title: '序号',
        width: 150,
        render: (record, value, index) => (
          <div>
            {index + 1}
          </div>
        )
      },
      {
        title: '老师',
        width: 150,
        dataIndex: 'teacherName'
      },
      {
        title: '手机号（账号）',
        width: 110,
        dataIndex: 'teacherPhone'
      },
      {
        title: '班级',
        width: 100,
        render: (record) => (
          <div>
            {record.classNames.join(',')}
          </div>
        )
      },
      {
        title: '操作',
        width: 150,
        render: (record) => (
          <div>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleEdit(record)}>
              编辑
            </a>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleDelete(record)}>
              删除
            </a>
          </div>
        )
      }
    ]
  }

  componentDidMount () {
    this.queryTeacherList()
  }

  // 教师列表
  queryTeacherList = () => {
    const { pages, name } = this.state
    const params = {
      schoolId: getStore('schoolId'),
      name,
      pageNo: pages.pageNum,
      pageSize: pages.pageSize
    }
    Api.queryTeacherList(params).then(({ res }) => {
      const { data, success, total } = res
      if (success) {
        this.setState({
          tableData: data,
          pages: {
            ...pages,
            total
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = () => {
    this.queryTeacherList()
  }

  // 翻页
  pageChange = (obj) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNum: obj.current,
        pageSize: obj.pageSize
      }
    }, () => {
      this.queryTeacherList()
    })
  }
  // 编辑老师信息
  handleEdit = (value) => {
    this.props.history.push(`/addTeacher?id=${value.teacherId}`)
  }
  // 删除老师
  handleDelete = (value) => {
    this.setState({
      modal: {
        deleteTeacher: true
      },
      teacherObj: {
        id: value.teacherId,
        name: value.teacherName
      }
    })
  }
  handleCancel = () => {
    this.setState({
      modal: {
        deleteTeacher: false
      },
    })
  }
  handleOk = () => {
    const { teacherObj } = this.state
    Api.deleteTeacher({ id: teacherObj.id }).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('删除成功')
        this.setState({
          modal: {
            deleteTeacher: false
          },
        }, () => {
          this.queryTeacherList()
        })
      }
    })
  }
  addTercher = () => {
    this.props.history.push('/addTeacher')
  }
  handleChangeName = (e) => {
    this.setState({
      name: e.target.value
    })
  }
  handleClear = () => {
    this.setState({
      name: ''
    }, () => {
      this.queryTeacherList()
    })
  }
  importProps = () => {
    const _this = this
    const props = {
      name: 'file',
      action: `${host}attendance/teacher/import`,
      headers: {
        Authorization: getStore('token'),
      },
      onChange (info) {
        if (info.file.status === 'done') {
          const { success } = info.file.response
          const _message = info.file.response.message
          if (success) {
            message.success(_message, 3, () => {
              _this.queryTeacherList()
            })
          } else {
            message.error(_message)
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }
      },
    }
    return props
  }
  render () {
    const { pages, tableData, modal, teacherObj, name } = this.state
    return (
      <div>
        <div style={{ padding: '20px' }}>
          <Input style={{ width: '250px' }} value={name}
            onChange={this.handleChangeName} placeholder='老师姓名/手机号/班级' type='text' />
          <Button onClick={this.handleSearch} type='primary' style={{ marginLeft: '20px' }}>搜索</Button>
          <Button onClick={this.handleClear} style={{ marginLeft: '20px' }}>清空</Button>
          <Button onClick={this.addTercher} type='primary' style={{ marginLeft: '20px' }}>添加老师</Button>
          <Button style={{ marginLeft: '20px' }} type='primary'>
            <a href='https://isus-attendance.oss-cn-hangzhou.aliyuncs.com/isus/csv/%E8%80%81%E5%B8%88%E9%80%9A%E8%AE%AF%E5%BD%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
              download='%E8%80%81%E5%B8%88%E9%80%9A%E8%AE%AF%E5%BD%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'>老师批量导入模板下载</a>
          </Button>
          <Upload {...this.importProps()}>
            <Button style={{ marginLeft: '20px' }} type='primary'>批量导入老师</Button>
          </Upload>
        </div>
        <div>
          <DataTable
            rowKey='id'
            columns={this.columns}
            list={tableData}
            total={pages.total}
            pageSize={pages.pageSize}
            page={pages.pageNum}
            onChange={this.pageChange}
          />
        </div>
        {
          modal.deleteTeacher
            ? <Modal
              title='提示'
              visible
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p>{`确认删除“${teacherObj.name}“老师`}</p>
            </Modal> : null
        }
      </div>
    )
  }
}

TeacherList.propTypes = {
  history: PropTypes.object.isRequired
}
