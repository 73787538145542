import React from 'react'
import { Card, Col, DatePicker, Row, Statistic, Table } from 'antd'
import styles from './classAttendance'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Api from '@/common/api'
import { getParams } from '@/common/utils/rowkey'

const dateFormat = 'YYYY/MM/DD'
export default class ClassAttendance extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      attendedNum: 0,
      totalNum: 0,
      info: { num: 0, total: 0, late: 0, leave: 0 },
      class: { name: '三年(2)班' },
      classList: [],
      classId: '',
      className: ''
    }

    this.columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        render: text => (
          <Link
            to={`/studentAttendance?name=${encodeURI(text.name)}&id=${
              text.studentId
            }`}
          >
            {text.name}
          </Link>
        )
        // <a href="javascript:;">{text}</a>
      },
      {
        title: '考勤记录',
        dataIndex: 'record',
        key: 'record'
      },
      {
        title: '状态',
        render: record => {
          switch (record.status) {
            case '0':
              return '未考勤'
            case '1':
              return '已考勤'
            case '2':
              return '迟到'
            case '3':
              return '请假'
            case '4':
              return '不用考勤'
          }
        }
      }
    ]
  }

  componentDidMount () {
    let paramsArr = this.props.location.search.split('?')[1].split('&')
    const classId = paramsArr[0].split('=')[1]
    const className = decodeURIComponent(paramsArr[1].split('=')[1])
    let date = moment().format('YYYY-MM-DD')
    let params = {
      classId: classId,
      date: date
    }
    const { id } = getParams()

    this.setState({
      classId: classId,
      className: className
    })
    this.getClassList(params)
  }

  getClassList (params) {
    Api.getClassAttendence(params).then(({ res }) => {
      const { data } = res
      let obj = {}
      obj.total = data.total
      obj.late = data.late
      obj.leave = data.leave
      obj.checked = data.checked
      this.setState({
        info: obj,
        class: { name: `${data.gradeName} ${data.className}` }
      })
      console.log('setstate', this.state.info)
      this.handleSetData(data.oneStudentCheckInfoList)
    })
  }

  handleSetData = data => {
    let lists = []
    if (data && data.length > 0) {
      data.forEach((item, index) => {
        // console.log(item)
        let str = ''
        if (item.checkLogList.length > 0) {
          item.checkLogList.forEach((e, i) => {
            str += e.hourTime + ' '
          })
        }
        let obj = {
          name: { name: item.name, studentId: item.studentId },
          record: str,
          status: item.status,
          studentId: item.studentId
        }
        lists.push(obj)
        this.setState({
          classList: lists
        })
        // console.log('====', this.state.classList)
      })
    } else {
      this.setState({
        classList: []
      })
    }
  }

  onChange (date, dateString) {
    // console.log(date, dateString)
    // console.log(this.state)
    let params = {
      classId: this.state.classId,
      date: dateString.replace(/\//g, '-')
    }
    this.getClassList(params)
  }

  disabledDate = current => {
    // return current < moment(new Date('2018/07/15')) || current > moment().endOf('day')
    return current > moment().endOf('day')
  }

  render () {
    const colClassStyle = {
      paddingTop: 32
    }

    return (
      <div>
        {/* <Header classInfo={this.state.class} /> */}
        <div className={`${styles.top}`}>
          <Card>
            <h1>{this.state.className}</h1>
            <p>全校考勤/{this.state.className}</p>
          </Card>
        </div>
        {/* <Statistics info={this.state.info} /> */}
        <div className={`${styles.top}`}>
          <Card>
            {/* <Dropdown
            overlay={menu}
            trigger={["hover"]}
            className={`${styles.chosen}`}
          >
            <a className="ant-dropdown-link" href="javascript:void(0)">
              选择班级 <Icon type="down" />
            </a>
          </Dropdown> */}
            {/* <DatePicker
            onChange={onChange}
            defaultValue={moment('2015/01/01', dateFormat)}
            format={dateFormat}
          /> */}
            <div className={`${styles.chosen}`}>
              <DatePicker
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                onChange={this.onChange.bind(this)}
                disabledDate={this.disabledDate}
              />
            </div>

            <Row>
              <Col className={`${styles.statisticsNum}`} span={6}>
                <div>
                  <Statistic
                    title="考勤人数/全班人数："
                    value={this.state.info.checked}
                    suffix={`/${this.state.info.total}`}
                  />
                </div>
              </Col>
              <Col className={`${styles.statisticsNum}`} span={6}>
                <div>
                  <Statistic
                    title="迟到："
                    value={this.state.info.late}
                  />
                </div>
              </Col>
              <Col className={`${styles.statisticsNum}`} span={6}>
                <div>
                  <Statistic
                    title="请假："
                    value={this.state.info.leave}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </div>
        {/* <List /> */}
        <div className={`${styles.top}`}>
          <Card>
            <Table
              columns={this.columns}
              dataSource={this.state.classList}
              id="table"
              rowKey="studentId"
            />
          </Card>
        </div>
      </div>
    )
  }
}
