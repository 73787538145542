import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Breadcrumb, Dropdown, Icon } from 'antd'
import styles from './headerbar'
import { getStore, removeStore } from '@/common/utils/localstore'

class Headerbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: false,
      schoolName: getStore('schoolName')
    }
  }

  // 点击缩放
  changeCollapsed = () => {
    let coll = !this.state.collapsed
    this.setState({
      collapsed: coll
    })
    this.props.onCollapsed(coll)
  }

  // 退出
  loginOut = () => {
    removeStore('token')
    removeStore('userInfo')
    location.href = '/#/login'
  }

  // 跳转
  JumpPath = () => {
    this.props.history.push({ pathname: '/changePassword' })
  }

  render () {
    const { collapsed, schoolName } = this.state
    const menu = (
      <Menu>
        <Menu.Item onClick={this.JumpPath}>
          <a href='javascript:void(0)'>修改密码</a>
        </Menu.Item>
        <Menu.Item onClick={this.loginOut}>
          <a>退出登录</a>
        </Menu.Item>
      </Menu>
    )

    const userInfo = JSON.parse(getStore('userInfo')) || {}

    return (
      <div className={styles.headerbar}>
        <a type='text' className={styles.sidertrigger} onClick={this.changeCollapsed} >
          <Icon style={{ fontSize: '22px' }}
            type={!collapsed ? 'menu-fold' : 'menu-unfold'}
          />
        </a>
        <div className={styles.custombread}>
          <Breadcrumb style={{ lineHeight: '64px' }}>
            <Breadcrumb.Item>{this.props.curMenu.title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={styles.usercon}>
          {/* <a className={styles.message}>
            <Icon type='mail' />
          </a> */}
          <span style={{ marginRight: '20px' }}>{schoolName}</span>
          <Dropdown overlay={menu}>
            <a className={styles.userdrop} href='javascript:void(0)'>
              {
                userInfo && Object.keys(userInfo).length > 0
                  ? <span style={{ paddingRight: '5px' }}>
                    {userInfo.userName}
                  </span> : null
              }
              <Icon type='down' />
            </a>
          </Dropdown>
        </div>
      </div>
    )
  }
}

Headerbar.propTypes = {
  onCollapsed: PropTypes.func,
  curMenu: PropTypes.object,
  history: PropTypes.object
}

export default Headerbar
