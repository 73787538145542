import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, DatePicker } from 'antd'
import moment from 'moment'
const { MonthPicker, RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const FormItem = Form.Item

class AddGradeModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = { dateArr: [] }
  }

  handleConfirm = e => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // if (isEdit) {
        //   values['studentId'] = detailInfo.studentId
        // }
        // values['classId'] = detailInfo.classId
        values.time = this.state.dateArr
        this.props.onOk(values)
      }
    })
  }

  handleCancel = e => {
    this.props.onCancel()
  }
  changeDate (date, dateString) {
    console.log('Selected Time: ', date)
    console.log('Formatted Selected Time: ', dateString)
    this.setState({
      dateArr: dateString,
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
      },
    }
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Modal
          visible
          maskClosable={false}
          title={'不考勤时间'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='原因' {...formItemLayout}>
                {getFieldDecorator('reason', {
                  rules: [{ required: true, message: '请输入原因!' }],
                })(<Input placeholder='请输入...' autoComplete='off' />)}
              </FormItem>
              <FormItem label='时间' {...formItemLayout}>
                {getFieldDecorator('time', {
                  rules: [{ required: true, message: '请输入时间!' }],
                })(
                  <RangePicker
                    onChange={this.changeDate.bind(this)}
                    defaultValue={[
                      moment('2015/01/01', dateFormat),
                      moment(new Date(), dateFormat),
                    ]}
                    format={dateFormat}
                  />,
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

AddGradeModalForm.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
}

const AddGradeModal = Form.create()(AddGradeModalForm)

export default AddGradeModal
