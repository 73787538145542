import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Tree, Button, message, Modal, Upload, Icon, Menu, Dropdown } from 'antd'

import DataTable from '@/components/data-table'
import { getStore } from '@/common/utils/localstore'
import { getData } from '@/common/utils/filter'
import config from '@/common/utils/config'
import Api from '@/common/api'

import AddStudentModal from './child/addStudent'
import ChangeTeacherModal from './child/changeTeacher'
import AddGradeModal from './child/addGrade'
import AddClassModal from './child/addClass'
const { TreeNode } = Tree
const confirm = Modal.confirm
const { host } = config

export default class ClassList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: { total: 0, pageNum: 1, pageSize: 10 },
      tableData:  [],
      query: {
      }, // 搜索条件
      treeData: [],
      selectedClass: {}, // 当前选中的班级
      modal: {
        addStudentModal: false,
        changeTeacherModal: false,
        addGradeModal: false,
        addClassModal: false
      },
      detailInfo: {},
      deleteNos: [], // 删除的学生的学号
      flag: 'school', // 当前点击的是学校还是年级还是班级
      expandedKeys: [],
      newTeacher: '',
      newGradeName: '',
      newClass: ''
    }
    // 订单列表表头 设置
    this.columns = [
      {
        title: '姓名',
        dataIndex: 'name'
      },
      {
        title: '性别',
        render: (record) => (
          <span>
            {record.sex === '1' ? '男' : '女'}
          </span>
        )
      },
      {
        title: '学号',
        dataIndex: 'no',
      },
      {
        title: '出生日期',
        render: (record) => (
          <span>{getData(record.birth, 'birth')}</span>
        )
      },
      {
        title: '智能校服',
        render: (record) => (
          <span>{+record.chkFlag === 0 ? '未开通' : '已开通'}</span>
        )
      },
      {
        title: '操作',
        width: 150,
        render: (record) => (
          <div>
            <a href='javascript:void(0)' style={{ display: 'block', marginBottom: '5px' }}
              onClick={() => this.handleAddStudent(record)}>
              编辑
            </a>
          </div>
        )
      }
    ]
  }

  componentDidMount = () => {
    this.handleGradeList(true)
    let arr = []
    arr.push(getStore('schoolId'))
    this.setState({
      expandedKeys: arr
    })
  }
  // 获取年级列表和班级列表
  handleGradeList = (value, type) => {
    const { flag } = this.state
    let params = {
      schoolId: getStore('schoolId')
    }
    Api.getGradeList(params).then(({ res }) => {
      const { data } = res
      this.handleSetData(data)
      if (data.length > 0 && data[0].classList.length > 0 && value) {
        this.setState({
          selectedClass: data[0].classList[0]
        })
        if (!type) {
          this.handleStudentList(data[0].classList[0], flag)
        }
      } else {
        if (!type) {
          this.handleStudentList(null, flag, getStore('schoolId'))
        }
      }
    })
  }
  // 学生列表
  handleStudentList = (item, flag, value) => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNum,
      pageSize: pages.pageSize
    }
    if (flag === 'school') {
      params.schoolId = item && item.schoolId ? item.schoolId : value
    } else if (flag === 'grade') {
      params.gradeId = item && item.gradeId ? item.gradeId : value
    } else if (flag === 'class') {
      params.classId = item && item.classId ? item.classId : value
    }
    Api.getStudentList(params).then(({ res }) => {
      const { data, total, pageNum, pageSize } = res
      this.setState({
        tableData: data,
        pages: {
          pageNum,
          pageSize,
          total,
        }
      })
    })
  }
  // 翻页
  pageChange = (obj) => {
    const { pages, selectedClass, flag } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNum: obj.current,
        pageSize: obj.pageSize
      }
    }, () => {
      this.handleStudentList(selectedClass, flag, getStore('schoolId'))
    })
  }
  renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode {...item} />
    });
  // 处理数据
  handleSetData = (values) => {
    values.forEach((item, index) => {
      item.key = item.gradeId
      item.flag = 'grade'
      item.title = (<span style={{ width: '200px', display: 'inline-block' }}>
        <span>{item.name}</span>
        <span style={{ float: 'right' }}>
          <Dropdown overlay={this.gradeMenu(item)}>
            <Icon type='more' />
          </Dropdown>
        </span>
      </span>)
      item.classList.forEach((el, ind) => {
        el.title = (<span style={{ width: '200px', display: 'inline-block' }}>
          <span>{el.name}</span>
          <span style={{ float: 'right' }}>
            <Dropdown overlay={this.classMenu(el)}>
              <Icon type='more' />
            </Dropdown>
          </span>
        </span>)
        el.key = el.classId
        el.flag = 'class'
      })
      item.children = item.classList
    })
    let arr = []
    let obj = {
      key: values[0] ? values[0].schoolId : getStore('schoolId'),
      title:(<span style={{ width: '200px', display: 'inline-block' }}>
        <span>{values[0] ? values[0].schoolName : getStore('schoolName')}</span>
        <span style={{ float: 'right' }}>
          <Dropdown overlay={this.schoolMenu()}>
            <Icon type='more' />
          </Dropdown>
        </span>
      </span>),
      children: values,
      flag: 'school',
    }
    arr.push(obj)
    this.setState({
      treeData: arr
    })
  }
  onSelect = (value, name) => {
    // 如果是学校，获取全部学生，
    // 如果是年级，获取年级学生
    if (name.selectedNodes[0].props.children) {
      this.setState({
        flag: name.selectedNodes[0].props.dataRef.flag,
        selectedClass: name.selectedNodes[0].props.dataRef.children[0],
        newGradeName: name.node.props.dataRef.name
      }, () => {
        this.handleStudentList(null, name.selectedNodes[0].props.dataRef.flag, value[0])
      })
    } else {
      this.setState({
        flag: 'class',
        selectedClass: name.selectedNodes[0].props,
        newTeacher: null,
        newClass: null
      }, () => {
        this.handleStudentList(name.selectedNodes[0].props, 'class')
      })
    }
  }
  // 添加学生
  handleAddStudent = (value) => {
    const { selectedClass, flag, newClass } = this.state
    if (!value && flag !== 'class') return message.error('请选择班级后，再添加学生')
    let detailInfo = {
      classId: value ? value.classId : selectedClass.classId,
      gradeName: value ? value.gradeName : selectedClass.gradeName,
      classname: value ? value.className : (newClass || selectedClass.name)
    }
    let isEdit = false
    if (value) {
      // 编辑
      detailInfo = {
        ...detailInfo,
        ...value
      }
      isEdit = true
    }
    this.setState({
      modal: {
        addStudentModal: true
      },
      detailInfo,
      isEdit
    })
  }
  // 更换班主任
  handleChangeTeacher = (teacherId, teacherName, classId) => {
    this.setState({
      modal: {
        changeTeacherModal: true
      },
      detailInfo: {
        teacherId,
        teacherName,
        classId
      }
    })
  }
  // 添加年级
  handleAddGrade = (value = {}, flag) => {
    let detailInfo = {
      name: '',
    }
    let isEdit = false
    if (flag) {
      // 编辑
      detailInfo = {
        name: value.name,
        gradeId: value.gradeId
      }
      isEdit = true
    }
    this.setState({
      modal: {
        addGradeModal: true
      },
      detailInfo,
      isEdit
    })
  }
  // 添加班级
  handleAddClass = (value, flag) => {
    let detailInfo = {
      schoolId: value.schoolId,
      gradeId: value.gradeId,
      name: '',
      sort: ''
    }
    let isEdit = false
    if (flag) {
      // 编辑
      detailInfo = {
        schoolId: value.schoolId,
        gradeId: value.gradeId,
        name: value.name,
        sort: value.sort,
        classId: value.classId
      }
      isEdit = true
    }
    this.setState({
      modal: {
        addClassModal: true
      },
      detailInfo,
      isEdit
    })
  }
  handleCancle = () => {
    this.setState({
      modal: {
        addStudentModal: false,
        changeTeacherModal: false,
        addGradeModal: false,
        addClassModal: false
      }
    })
  }
  // 更换班主任
  handleChangeTeacherFn = (values) => {
    Api.saveTeacher(values).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('更换成功')
        this.setState({
          modal: {
            changeTeacherModal: false
          },
          newTeacher: values.teacherName
        }, () => {
          this.handleGradeList(null, 'no')
        })
      }
    })
  }
  saveStudentFn = (values) => {
    const { selectedClass, flag } = this.state
    values['schoolId'] = getStore('schoolId')
    Api.addStudent(values).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功！')
        this.setState({
          modal: {
            addStudentModal: false
          }
        }, () => {
          this.handleStudentList(selectedClass, flag)
        })
      }
    })
  }
  // 添加年级
  addGradeFn = (values) => {
    values['schoolId'] = getStore('schoolId')
    const { selectedClass, flag } = this.state
    Api.addGrade(values).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功！')
        this.setState({
          modal: {
            addGradeModal: false
          },
          newGradeName: values.name
        }, () => {
          this.handleGradeList(null, 'no')
          // this.handleStudentList(selectedClass, flag)
        })
      }
    })
  }
  // 添加班级
  addClassFn = (values) => {
    Api.addClass(values).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功！')
        this.setState({
          modal: {
            addClassModal: false
          },
          newClass: values.name
        }, () => {
          this.handleGradeList(null, 'no')
        })
      }
    })
  }
  // 删除学生
  removeStudent = () => {
    const { deleteNos, selectedClass, flag } = this.state
    const _this = this
    if (deleteNos.length === 0) {
      message.error('请选择要删除的学生！')
    } else {
      confirm({
        title: '提示',
        content: '确定要删除选择的学生？',
        onOk () {
          let params = {
            studentIds: deleteNos,
            type: '2',
            classId: selectedClass.classId
          }
          Api.removeStudent(params).then(({ res }) => {
            const { success, message } = res
            if (success) {
              message.success('删除成功', 3, () => {
                _this.handleStudentList(selectedClass, flag)
              })
            } else {
              message.error(message)
            }
          })
        },
      })
    }
  }
  // 导入学生
  importProps = () => {
    const { selectedClass, flag } = this.state
    const _this = this
    const props = {
      name: 'file',
      action: `${host}/attendance/student/import`,
      headers: {
        Authorization: getStore('token'),
      },
      onChange (info) {
        if (info.file.status === 'done') {
          const { success } = info.file.response
          const _message = info.file.response.message
          if (success) {
            message.success(_message, 3, () => {
              if (flag === 'school') {
                _this.handleGradeList(true, 'no')
                _this.handleStudentList(null, flag, getStore('schoolId'))
              } else {
                _this.handleStudentList(selectedClass, flag, getStore('schoolId'))
              }
            })
          } else {
            message.error(_message)
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }
      },
    }
    return props
  }
  // 学校菜单
  schoolMenu = () => {
    const props = (<Menu>
      <Menu.Item>
        <a onClick={() => this.handleAddGrade({}, false)} href='javascript:;'>添加年级</a>
      </Menu.Item>
    </Menu>)
    return props
  }
  // 年级菜单
  gradeMenu = (item) => {
    const props = (<Menu>
      <Menu.Item>
        <a onClick={() => this.handleAddGrade(item, true)} href='javascript:;'>修改名称</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => this.handleAddClass(item, false)} href='javascript:;'>添加班级</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => this.handleDelGrade(item)} href='javascript:;'>删除</a>
      </Menu.Item>
    </Menu>)
    return props
  }
  // 班级菜单
  classMenu = (item) => {
    const props = (<Menu>
      <Menu.Item>
        <a onClick={() => this.handleAddClass(item, true)} href='javascript:;'>修改名称</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => this.handleDelClass(item)} href='javascript:;'>删除</a>
      </Menu.Item>
    </Menu>)
    return props
  }
  // 删除年级
  handleDelGrade = (item) => {
    const { selectedClass, flag } = this.state
    let params = {
      id: item.gradeId
    }
    Api.deleteGrade(params).then(({ res }) => {
      const { success } = res
      const _message = res.message
      if (success) {
        message.success('删除成功')
        this.handleGradeList()
        this.handleStudentList(selectedClass, flag)
      } else {
        message.error(_message)
      }
    })
  }
  // 删除班级
  handleDelClass = (item) => {
    const { selectedClass, flag } = this.state
    let params = {
      id: item.classId
    }
    Api.deleteClass(params).then(({ res }) => {
      const { success } = res
      const _message = res.message
      if (success) {
        message.success('删除成功')
        this.handleGradeList()
        this.handleStudentList(selectedClass, flag)
      } else {
        message.error(_message)
      }
    })
  }
  handleSetName = (flag, selectedClass = {}, pages) => {
    const { newTeacher, newGradeName, newClass } = this.state
    if (flag === 'school') {
      return (<div style={{ margin: '0 0 20px 20px' }}>
        {`${getStore('schoolName')} (${pages.total}人)`}
      </div>)
    } else if (flag === 'grade') {
      return (<div style={{ margin: '0 0 20px 20px' }}>
        {`${newGradeName || selectedClass.gradeName} (${pages.total}人)`}
      </div>)
    } else if (flag === 'class') {
      return (<div style={{ margin: '0 0 20px 20px' }}>
        <span>
          {
            `${selectedClass.gradeName} ${newClass || selectedClass.name} (${pages.total}人)
        班主任：${newTeacher || selectedClass.teacherName || '暂无班主任'}
        `
          }
        </span>
        <a style={{ marginLeft: '10px' }}
          onClick={() => this.handleChangeTeacher(selectedClass.teacherId,
            newTeacher || selectedClass.teacherName, selectedClass.classId)} href='javascript:;'>更换</a>
      </div>)
    }
  }
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    })
  }
  render () {
    const { pages, tableData, treeData, selectedClass, modal, detailInfo,
      isEdit, flag, expandedKeys } = this.state
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let arr = []
        if (selectedRows.length > 0) {
          selectedRows.forEach(el => arr.push(el.studentId))
          this.setState({
            deleteNos: arr
          })
        }
      },
    }
    return (
      <div style={{ paddingTop: '20px' }}>
        <Row type='flex'>
          <Col span={6}>
            <Tree
              expandedKeys={expandedKeys}
              onExpand={this.onExpand}
              onSelect={this.onSelect}
            >
              {this.renderTreeNodes(treeData)}
            </Tree>
          </Col>
          <Col span={18}>
            {
              this.handleSetName(flag, selectedClass, pages)
            }
            <div style={{ margin: '0 0 20px 20px' }}>
              <Button
                onClick={() => this.handleAddStudent()}
                style={{ marginRight: '20px' }} type='primary'>添加学生</Button>
              <Button onClick={this.removeStudent} style={{ marginRight: '20px' }} type='primary'>删除</Button>
              <Button style={{ marginRight: '20px' }} type='primary'>
                <a href='https://isus-attendance.oss-cn-hangzhou.aliyuncs.com/isus/csv/%E5%AD%A6%E7%94%9F%E9%80%9A%E8%AE%AF%E5%BD%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
                  download='%E5%AD%A6%E7%94%9F%E9%80%9A%E8%AE%AF%E5%BD%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'>学生批量导入模板下载</a>
              </Button>
              <Upload style={{ display: 'inlineBlock' }} {...this.importProps()}>
                <Button style={{ marginRight: '20px' }} type='primary'>批量导入学生</Button>
              </Upload>
            </div>
            <DataTable
              rowKey='id'
              rowSelection={rowSelection}
              columns={this.columns}
              list={tableData}
              total={pages.total}
              pageSize={pages.pageSize}
              page={pages.pageNum}
              onChange={this.pageChange}
            />
          </Col>
        </Row>
        {
          modal.addStudentModal
            ? <AddStudentModal
              isEdit={isEdit}
              detailInfo={detailInfo}
              onCancel={this.handleCancle}
              onOk={this.saveStudentFn}
            />
            : null
        }
        {
          modal.changeTeacherModal
            ? <ChangeTeacherModal
              detailInfo={detailInfo}
              onCancel={this.handleCancle}
              onOk={this.handleChangeTeacherFn}
            />
            : null
        }
        {
          modal.addGradeModal
            ? <AddGradeModal
              detailInfo={detailInfo}
              isEdit={isEdit}
              onOk={this.addGradeFn}
              onCancel={this.handleCancle}
            />
            : null
        }
        {
          modal.addClassModal
            ? <AddClassModal
              isEdit={isEdit}
              detailInfo={detailInfo}
              onOk={this.addClassFn}
              onCancel={this.handleCancle}
            />
            : null
        }
      </div>
    )
  }
}

ClassList.propTypes = {
  history: PropTypes.object.isRequired
}
