import React from 'react'
import { Button, Card, Col, DatePicker, Modal, Row, Select, Table } from 'antd'
import styles from './schoolAttendance'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Api from '@/common/api'
import { getParams } from '@/common/utils/rowkey'
import { getStore } from '@/common/utils/localstore'
import OutportModal from './component/outport.jsx'
import axios from 'axios'
import config from '@/common/utils/config'
import { dateFormatTime } from '@/common/utils/filter'

const { host } = config
const { Option } = Select
const dateFormat = 'YYYY/MM/DD'

export default class SchoolAttendance extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isExport: false,
      expertDate: '',
      data: null,
      attendedNum: 0,
      totalNum: 0,
      info: { num: 0, total: 0, late: 0, leave: 0 },
      class: { name: '三年(2)班' },
      classList: [],
      gradeList: [],
      attendance: {},
      attendanceData: {},
      showModal: false,
      showDetail: false,
      detailList: [],
      classIndex: null
    }
    this.columns = [
      {
        title: '班级',
        // dataIndex: 'name',
        render: record => (
          <Link
            to={`/classAttendance?name=${
              record.classId
            }&title=${encodeURIComponent(record.name)}`}
          >
            {record.name}
          </Link>
        )
        // <a href="javascript:;">{record}</a>
      },
      {
        title: '班主任',
        render: record => <span>{record.teacherName}</span>
      },
      {
        title: '考勤人数/班级人数',
        // dataIndex: 'attendanceNum',
        render: record => (
          <span
            onClick={() => {
              this.showDetail(record.classId)
            }}
          >{record.attendanceNum || null}</span>
        )
      },
      {
        title: '温度异常人数',
        dataIndex: 'highTemperatureNo'
      },
      {
        title: '请假',
        dataIndex: 'leave'
      },
      {
        title: '迟到',
        width: 150,
        dataIndex: 'late'
      }
    ]
  }

  componentDidMount () {
    // console.log(this.state.info);
    this.initData()
  }

  initData (date) {
    const schoolId = getStore('schoolId')
    let params = {
      schoolId: schoolId
    }
    const { id } = getParams()
    let schoolParams = { schoolId: schoolId, date: date || null }
    this.getSchoolAttendence(schoolParams)
    this.getGradeList(params)
  }

  getSchoolAttendence (params, index) {
    try {
      Api.getSchoolAttendence(params)
        .then(({ res }) => {
          let { data } = res
          this.setState({
            attendance: data
          })
          this.handleAttendance(data.checkCountList)
          console.log('------this.state.attendance------', this.state.attendance)
        })
        .catch(e => {
          // console.log("school", e);
        })
    } catch (error) {
      // console.log("scccc", error);
    }
  }

  getGradeList (params, index) {
    Api.getGradeList(params).then(({ res }) => {
      const { data } = res
      this.setState({
        data: data
      })
      this.handleSetData(data)
      if (this.state.classIndex) {
        this.onChange(this.state.classIndex)
      }
    })
  }

  showDetail (id) {
    let params = { classId: id }
    Api.getClassAttendence(params).then(({ res }) => {
      const { data } = res
      this.setState({
        detailList: data.oneStudentCheckInfoList
      })
      // console.log("APi".this.state.detailList);
    })
    this.state.showDetail = true
  }

  outportList = values => {
    values['schoolId'] = getStore('schoolId')
    Api.addGrade(values).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功！')
        this.setState(
          {
            showModal: false
          }
          // () => {
          //   this.handleGradeList()
          // },
        )
      }
    })
  }

  onChange (date, dateString) {
    // console.log(date, this.state.data)
    let list = []
    this.state.data.map((el, index) => {
      if (index === date) {
        el.classList.forEach((e, i) => {
          let idObj = {
            classId: e.classId,
            schoolId: e.schoolId,
            gradeId: e.gradeId
          }
          let obj = {
            name: `${e.gradeName} ${e.name}`,
            id: JSON.stringify(idObj),
            teacherName: e.teacherName,
            classId: e.classId
          }
          list.push(obj)
        })

        this.setState({
          classList: list,
          classIndex: date
        })
        // console.log(el.classList);
      }
    })
    // console.log(this.state.classList);
  }

  handleSetData = data => {
    let lists = []
    let grade = []
    data.forEach((item, index) => {
      grade.push({
        grade: item.name,
        classID: item.gradeId
      })
      if (item.classList.length > 0) {
        item.classList.map((el, ind) => {
          let idObj = {
            classId: el.classId,
            schoolId: el.schoolId,
            gradeId: el.gradeId
          }
          // let classParams = {classId: el.classId}
          // Api.getClassAttendence(classParams).then(({res}) => {
          //   console.log('classs', res)
          // })
          let obj = {
            name: `${el.gradeName} ${el.name}`,
            id: JSON.stringify(idObj),
            classId: el.classId,
            teacherName: el.teacherName
          }
          if (index === 0) {
            lists.push(obj)
          }
          this.setState({
            classList: lists,
            gradeList: grade
          })
        })
      }
      // this.setState({

      // });
    })
    setTimeout(() => {
      // console.log(this.state.classList);
    }, 0)
    // console.log(data);
  }

  handleAttendance (params) {
    let attendanceData = {}
    params.forEach((el, index) => {
      attendanceData[el.classId] = {
        totalNo: el.totalNo,
        checkedNo: el.checkedNo,
        lateNo: el.lateNo,
        leaveNo: el.leaveNo,
        highTemperatureNo: el.highTemperatureNo
      }
    })
    this.setState({
      attendanceData: attendanceData
    })
  }

  disabledDate = current => {
    // return current < moment(new Date('2018/07/15')) || current > moment().endOf('day')
    return current > moment().endOf('day')
  }

  chooseDate (date, dateString) {
    // console.log(date)
    let dateStr = dateString.replace(/\//g, '-')
    this.initData(dateStr)
    this.setState({
      expertDate: dateStr
    })
  }

  handleShowModal = () => {
    const param = {
      schoolId: getStore('schoolId') || '',
      schoolName: getStore('schoolName') || '',
      from: this.state.expertDate || moment().format('L').replace(/\//g, '-'),
      to: this.state.expertDate || moment().format('L').replace(/\//g, '-')
    }
    this.setState({
      isExport: true
    })
    const xlsx = dateFormatTime(param.to, 'YYYY年MM月DD日')
    axios({
      method: 'post',
      url: host + 'attendance/exportStudentStatus',
      data: param,
      responseType: 'blob',
      headers :{
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'token': token
      }
    }).then(res => {
      if (!res) return
      let blob = new Blob([res.data],
        { type: 'application/vnd.ms-excel;charset=utf8' }
      )
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `${param.schoolName}${xlsx}考勤详情.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      this.setState({
        isExport: false
      })
      document.body.removeChild(downloadElement)
      window.URl.revokeObjectURL(href)
    }).catch(err => {
      console.log(err)
    })
  }

  handleCancel () {
    this.setState({
      showDetail: false,
      showModal: false
    })
  }

  render () {
    const schoolDateStyle = {
      marginTop: -1
    }
    const show = {
      display: 'block'
    }
    const hidden = {
      display: 'none'
    }

    const { pages, showModal, showDetail, isExport } = this.state
    let classArr = []
    this.state.classList.forEach((element, index) => {
      let { classId } = element
      if (this.state.attendanceData[classId]) {
        element.attendanceNum = `${
          this.state.attendanceData[classId].checkedNo
        }/${this.state.attendanceData[classId].totalNo}`

        element.late = this.state.attendanceData[classId].lateNo
        element.leave = this.state.attendanceData[classId].leaveNo
        element.highTemperatureNo = this.state.attendanceData[classId].highTemperatureNo
      }
      classArr.push(element)
    })
    return (
      <div>
        <div className={`${styles.top}`}>
          <Card>
            <h1>{this.state.name}</h1>
            <p>全校考勤/{this.state.name}</p>
            <div className={styles.chosen}>
              <DatePicker
                style={schoolDateStyle}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                onChange={this.chooseDate.bind(this)}
                disabledDate={this.disabledDate}
              />
              {isExport ? (
                <Button className={styles.button}>导出中....</Button>
              ) : (<Button className={styles.button} onClick={this.handleShowModal.bind(this)}>导出全校点名册</Button>)
              }
            </div>
            <div/>
            <Row>
              <Col className={styles.statisticsNum} span={6}>
                <span>考勤人数/全校人数</span>
                {this.state.attendance.checked}/{this.state.attendance.total}
              </Col>

              <Col className={styles.statisticsNum} span={6}>
                <span>迟到：</span>
                {this.state.attendance.late}
              </Col>
              <Col className={styles.statisticsNum} span={6}>
                <span>请假：</span>
                {this.state.attendance.leave}
              </Col>
              <Col className={styles.statisticsNum} span={6}>
                <span>温度异常：</span>
                {this.state.attendance.highTemperatureNo}
              </Col>
            </Row>
          </Card>
        </div>
        <div className={`${styles.top}`}>
          <div className={`${styles.searchArea}`}>
            {/* <Dropdown
          overlay={menu}
          trigger={["hover"]}
          // className={`${styles.chosen}`}
        >
          <a className="ant-dropdown-link" href="javascript:void(0)">
            选择班级 <Icon type="down" />
          </a>
        </Dropdown> */}
            <Select
              showSearch
              style={{ width: 200, marginBottom: '20px' }}
              placeholder="请选择年级"
              optionFilterProp="children"
              onChange={this.onChange.bind(this)}
              defaultValue={0}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.gradeList.map((child, index) => {
                return (
                  <Option key={index} value={index}>
                    {child.grade}
                  </Option>
                )
              })}
            </Select>

            {/* <DatePicker
            onChange={onChange}
            defaultValue={moment('2015/01/01', dateFormat)}
            format={dateFormat}
          /> */}
            {/* <Button className={`${styles.searchBtn}`} icon={"search"} /> */}
          </div>
          <Card>
            <Table columns={this.columns} rowKey='classId' dataSource={classArr} id='table'/>
          </Card>
        </div>
        {showModal ? (
          <OutportModal
            onOk={this.outputList}
            onCancel={this.handleCancel.bind(this)}
          />
        ) : null}
        {
          <Modal
            title="未入园"
            visible={showDetail}
            onOk={this.handleOk}
            onCancel={this.handleCancel.bind(this)}
          >
            {this.state.detailList.map(item => {
              return <p>{item.name}</p>
            })}
          </Modal>
        }
      </div>
    )
  }
}
