import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Form,
  Button,
  message,
  Row,
  Col,
  Typography,
  Upload,
  Icon,
  Modal,
  Checkbox,
  Cascader
} from 'antd'
import Api from '@/common/api'
import { getStore } from '@/common/utils/localstore'
import addressData from '@/assets/json/city.json'
import example from '@/assets/images/example.png'
const { TextArea } = Input
const { Title } = Typography
const FormItem = Form.Item
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

 
}
class SchoolInfo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teacherDetail: {},
      isEdit: false,
      classList: [],
      classes: [],
      loading: false,
      loadingMuti: false,
      previewVisible: false,
      previewImage: '',
      badge: '',
      faceUrl: '',
      honorUrl: '',
      showAlert: false,
      showAlertBadge: false,
      fileList: [
        //   {
        //   uid: '-1',
        //   name: 'xxx.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
      ],
      addressObj: { province: '', city: '',area:'', address: '' },
      schoolInfo: {},
      type: '',
      picList: [],
      previewExample: false,
      previewBadge: '',
      previewBadgeVisible: false,
      badgeList: [],
      honorPicList: [],
      previewHonor: '',
      previewHonorVisible: false,
    }
  }

  componentDidMount () {
    this.querySchoolInfo()
  }
  querySchoolInfo = () => {
    let params = {
      id: getStore('schoolId')
    }
    Api.querySchoolInfo(params).then(({ res }) => {
      const { data } = res
      let arr = []
      data.picList.map(el => {
        arr.push({ url: el.img, uid: el.schoolPicId })
      })
      let badgeList = data.badge
        ? [{ url: data.badge, uid: data.schoolId }]
        : []
      let honorPicList = data.faceUrl
        ? [{ url: data.faceUrl, uid: data.schoolId }]
        : []
      this.setState({
        schoolInfo: data,
        badge: data.badge,
        faceUrl: data.faceUrl,
        honorUrl: data.faceUrl || null,
        type: data.type.split(','),
        addressObj: {
          province: data.province,
          city: data.city,
          area:data.area,
          address: data.address
        },
        fileList: arr,
        badgeList: badgeList,
        honorPicList: honorPicList
      })
    })
  };
  handleChange = ({ file, fileList }) => {
    // console.log('xiaohui', file)
    // console.log('xioahuihuihui', fileList)
    this.setState({ badgeList: fileList })
    if (file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (file.status === 'done' || file.status === 'removed') {
      // console.log('=====', fileList)
      this.setState({
        loading: false,
        showAlertBadge: (fileList[0] && fileList[0].response && fileList[0].response.data)?false:true,
        badge:
          (fileList[0] && fileList[0].response && fileList[0].response.data) ||
          ''
      })
    }
  };
  handleChangeMuti = ({ file, fileList }) => {
    // console.log('file', file)
    // console.log('fileList', fileList)
    this.setState({ fileList })
    let picList = []
    fileList.forEach((el,index) => {
      // console.log('el', el)
      if (el.response) {
        picList.push({ img: el.response.data,sort:index })
      }
    })
    if (file.status === 'uploading') {
      this.setState({ loadingMuti: true })
      return
    }
    if (file.status === 'done'|| file.status === 'removed') {
      this.setState({
        loading: false,
        showAlert: fileList.length>=1?false:true,
        picList: picList
      })
    }
  };
  handleCancel = () =>
    this.setState({
      previewVisible: false,
      previewExample: false,
      previewBadgeVisible: false,
      previewHonorVisible: false
    });

  handlePreview = file => {
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    })
  };
  handlePreviewBadge = file => {
    // console.log('badge', file)
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj)
    }

    this.setState({
      previewBadge: (file.response && file.response.url) || file.url,
      previewBadgeVisible: true
    })
  };
  handlePreviewHonor = file => {
    // console.log('previe honor', file)
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj)
    }

    this.setState({
      previewHonor: (file.response && file.response.data) || file.url,
      previewHonorVisible: true
    })
  };
  handleChangeM = ({ fileList }) => {
    // console.log(fileList)
    this.setState({ fileList })
  };
  handleCreate = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { type, faceUrl, badge, addressObj, honorUrl } = this.state
      values.schoolId = getStore('schoolId')
      values.badge = badge
      values.address = addressObj.address
      values.province = addressObj.province
      values.area = addressObj.area
      values.city = addressObj.city
      values.type = type.join(',')
      let arr = []
      // console.log('--', badge)
      if (this.state.fileList.length > 0) {
        this.state.fileList.map((el,index) => {
          if (el.url) {
            arr.push({ img: el.url ,sort:index})
          }
        })
      }
      values.picList = arr.concat(this.state.picList)
      values.faceUrl = honorUrl
      // (values.picList && values.picList[0] && values.picList[0].img) ||
      // faceUrl;
      if (!values.picList || values.picList.length === 0) {
        this.setState({
          showAlert: true
        })
        err = true
      }
      if (!values.badge) {
        this.setState({
          showAlertBadge: true
        })
        err = true
      }
      if (!err) {
        // if (true) {
        // } else {
        Api.getEditInfo(values)
          .then(({ res }) => {
            const { success } = res
            const _message = res.message
            if (success) {
              message.success('保存成功')
            } else {
              message.error(_message)
            }
          })
          .catch(e => {
            // console.log(e)
          })
      }
      // }
    })
  };
  handleCheckboxChange = checkedValues => {
    this.setState({
      type: checkedValues
    })
  };
  // 省市区三级联动数据包装
  formatData = () => {
    const adders = []
    for (let province in addressData) {
      const label = addressData[province].name
      const city = addressData[province].city
      const children = []
      for (let n = 0, len = city.length; n < len; n++) {
        let areaArr = []
        city[n].area.forEach(el=>{
          areaArr.push({value:el,label:el})
        })
        let cityList = {
          label: city[n].name,
          value: `${city[n].name}`,
          // children: []
          children:areaArr
        }
        if (city[n].name === '其他') {
          cityList['value'] = `a-${city[n].name}`
        }
        children.push(cityList)
      }
      const data = {
        label,
        value: label,
        children
      }
      adders.push(data)
    }
    return adders
  };
  onChangeCity = val => {
    const { addressObj } = this.state
    this.setState(
      {
        addressObj: {
          ...addressObj,
          province: val[0],
          city: val[1],
          area:val[2]
        }
      },
      () => {}
    )
  };
  handleAddressChange = val => {
    const { addressObj } = this.state
    this.setState({
      addressObj: {
        ...addressObj,
        address: val.target.value
      }
    })
  };
  // handleChangeIntr = val => {
  //   console.log("简介", val);
  //   let { file } = val;
  //   this.setState({
  //     honorUrl: file.response.data
  //   });
  // };
  handleChangeIntr = val => {
    // console.log('简介', val)
    let { file, fileList } = val
    this.setState({ honorPicList: fileList })
    this.setState({
      honorUrl: (file.response && file.response.data) || ''
    })
  };
  validatePicList = (rule, value, callback) => {
    // console.log(rule, value), callback()
  };
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
    const { getFieldDecorator } = this.props.form
    const {
      previewVisible,
      previewImage,
      schoolInfo,
      badge,
      faceUrl,
      honorUrl,
      addressObj,
      picList,
      fileList,
      previewExample,
      showAlert,
      showAlertBadge,
      previewBadge,
      previewBadgeVisible,
      badgeList,
      honorPicList,
      previewHonor,
      previewHonorVisible
    } = this.state
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className='ant-upload-text'>Upload</div>
      </div>
    )
    return (
      <div style={{ paddingTop: '20px' }}>
        <div>
          <Title
            type='secondary'
            style={{ fontSize: '20px', display: 'inline-block' }}
          >
            学校信息
          </Title>
          <Title
            type='secondary'
            style={{
              fontSize: '16px',
              display: 'inline-block',
              marginLeft: '30px'
            }}
          >
            <span style={{ color: '#f5222d' }}>*</span>
            学校信息将会自动生成宣传海报，信息越完整，平台中曝光概率越高哦！
            <a
              href='javascript:void(0)'
              onClick={() => {
                this.setState({
                  previewExample: true
                })
              }}
            >
              显示示例
            </a>
          </Title>
        </div>
        <Form>
          <FormItem label='学校名称' {...formItemLayout}>
            {getFieldDecorator('name', {
              initialValue: schoolInfo.name || '',
              // rules: [{ required: true, message: '请输入学校名称!' }]
            })(
              <Input
                placeholder='请输入学校名称'
                // maxLength={10}
                disabled
                autoComplete='off'
              />
            )}
          </FormItem>
          <Form.Item label='校徽或logo' {...formItemLayout}>
            {getFieldDecorator('badge', {
							initialValue:badgeList,
              rules: [{ required: true, message: ' ' }]
            })(
              <div>
                {/* {badge ? <img src={badge} alt="avatar" /> : uploadButton} */}

                <Upload
                  action='https://dev.mm-iworld.com/user/upload/file'
                  listType='picture-card'
                  fileList={badgeList}
                  onPreview={this.handlePreviewBadge}
                  onChange={this.handleChange}
                >
                  {badgeList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewBadgeVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt='example'
                    style={{ width: '100%' }}
                    src={previewBadge}
                  />
                </Modal>
              </div>
            )}
            {showAlertBadge ? (
              <Row style={{ marginBottom: '10px', textAlign: 'left' }}>
                <Col />
                <span style={{ color: '#f5222d' }}>
                  请上传校徽或logo！
                </span>
              </Row>
            ) : null}
          </Form.Item>
          <FormItem {...formItemLayout} label='学校类型'>
            {getFieldDecorator('type', {
              initialValue: schoolInfo.type || [],
              rules: [{ required: true, message: '请输入学校类型!' }]
            })(
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.handleCheckboxChange}
              >
                <Row>
                  <Col span={6}>
                    <Checkbox value='0'>幼儿园</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value='1'>小学</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value='2'>初中</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value='3'>高中</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            )}
          </FormItem>
          <FormItem label='学校地址' {...formItemLayout}>
            {
              <Row>
                <Cascader
                  style={{ width: '200px' }}
                  value={[addressObj.province, addressObj.city,addressObj.area]}
                  onChange={value => {
                    this.onChangeCity(value)
                  }}
                  options={this.formatData()}
                  // options={this.options}
                  placeholder='请选择省、市、区'
                />
                {getFieldDecorator('address', {
                  initialValue: addressObj.address || '',
                  rules: [{ required: true, message: '请输入学校地址!' }]
                })(
                  <Input
                    onChange={this.handleAddressChange}
                    style={{ width: '384px', marginLeft: '20px' }}
                    type='text'
                    placeholder='请输入详细地址'
                    // value={addressObj.address}
                  />
                )}
              </Row>
            }
          </FormItem>

          <Form.Item label='校训' {...formItemLayout}>
            {getFieldDecorator('rule', {
              initialValue: schoolInfo.rule || ''
            })(
              <Input
                placeholder='请输入学校校训'
                // maxLength={10}
                autoComplete='off'
              />
            )}
          </Form.Item>
          <Form.Item label='学校简介' {...formItemLayout}>
            {getFieldDecorator('introduce', {
              initialValue: schoolInfo.introduce || ''
            })(
              <TextArea placeholder='请输入学校简介' rows={3} />
              // <Input
              //   placeholder="请输入学校简介"
              //   // maxLength={100}
              //   autoComplete="off"
              // />
            )}
          </Form.Item>
          <Form.Item label='学校荣誉' {...formItemLayout}>
            {
              <div>
                <Upload
                  action='https://dev.mm-iworld.com/user/upload/file'
                  listType='picture-card'
                  fileList={honorPicList}
                  onPreview={this.handlePreviewHonor.bind(this)}
                  onChange={this.handleChangeIntr}
                >
                  {honorPicList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewHonorVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt='example'
                    style={{ width: '100%' }}
                    src={previewHonor}
                  />
                </Modal>
              </div>
              // <Upload
              //   action="https://dev.mm-iworld.com/user/upload/file"
              //   showUploadList={false}
              //   onChange={this.handleChangeIntr.bind(this)}
              //   listType="picture-card"
              // >
              //   {honorUrl ? <img src={honorUrl} alt="avatar" /> : uploadButton}
              // </Upload>
            }
            {getFieldDecorator('reward', {
              initialValue: schoolInfo.reward || '',
            })(
              <Input
                placeholder='请填写学校荣誉'
                // maxLength={10}
                autoComplete='off'
              />
            )}
          </Form.Item>
          <Form.Item label='校园风采' {...formItemLayout}>
            {
              getFieldDecorator('fileList', {
								initialValue:fileList,
                rules: [{ required: true, message: ' ' }]
              })(
                <div>
                  <Upload
                    action='https://dev.mm-iworld.com/user/upload/file'
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChangeMuti}
                  >
                    {fileList.length >= 4 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt='example'
                      style={{ width: '100%' }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              )
            }
            {showAlert ? (
              <Row style={{ marginBottom: '10px', textAlign: 'left' }}>
                <Col />
                <span style={{ color: '#f5222d' }}>
                  请至少上传一张图片
                </span>
              </Row>
            ) : null}
          </Form.Item>
          {/* <Form.Item>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChangeM}
            >
              {fileList.length >= 3 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Form.Item> */}
        </Form>
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <Button
            type='primary'
            onClick={this.handleCreate}
            style={{ marginRight: '20px' }}
          >
            保存
          </Button>
          <Button onClick={this.handleCancle}>取消</Button>
        </div>
        {previewExample ? (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100vh'
            }}
          >
            <Modal
              visible={previewExample}
              footer={null}
              onCancel={this.handleCancel}
              bodyStyle={{ padding: 0 }}
            >
              <img alt='example' style={{ height: '100%' }} src={example} />
            </Modal>
          </div>
        ) : null}
      </div>
    )
  }
}

SchoolInfo.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object.isRequired
}

const schoolInfo = Form.create()(SchoolInfo)

export default schoolInfo
