import React from 'react'
import { Route } from 'react-router-dom'

import ClassList from './classList' // 班级列表

export default (
  <React.Fragment>
    <Route path='/classList' component={ClassList} />
  </React.Fragment>
)
