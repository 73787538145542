import React, { Component } from "react";
import {
	Card,
	Button,
	Select,
	Cascader,
	Row,
	Col,
	Modal,
	DatePicker,
	Table,
	Input
} from "antd";
import styles from "./parents";
import { Link } from "react-router-dom";
import moment from "moment";
import Api from "@/common/api";
import { getParams } from "@/common/utils/rowkey";
import { getStore } from "@/common/utils/localstore";
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";
const options = [
	{
		value: "zhejiang",
		label: "Zhejiang",
		children: [
			{
				value: "hangzhou",
				label: "Hangzhou"
			}
		]
	},
	{
		value: "jiangsu",
		label: "Jiangsu",
		children: [
			{
				value: "nanjing",
				label: "Nanjing"
			}
		]
	}
];

export default class ParentsIdentity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			class: { name: "三年(2)班" },
			classList: [],
			pages: { total: 0, pageNum: 1, pageSize: 10 },
			currentPage: 1,
			gradeList: [],
			attendance: {},
			attendanceData: {},
			showModal: false,
			showDetail: false,
			detailList: [],
			grade: {},
			classIndex: null,
			record: [],
			total: 0,
			name: null,
			gradeName: null,
			className: null,
			dateNow: null
		};
		this.columns = [
			{
				title: "时间",
				dataIndex: "time",
				key: "time",
				render: record => (
					<div>
						<div>{record.date}</div>
						<div>{record.time}</div>
					</div>
				)
				// render: record => (
				// 	<Link
				// 		to={`/classAttendance?name=${
				// 			record.classId
				// 		}&title=${encodeURIComponent(record.name)}`}
				// 	>
				// 		{record.time}
				// 	</Link>
				// )
				// <a href="javascript:;">{record}</a>
			},
			{
				title: "账号",
				dataIndex: "name",
				key: "name",
				render: record => (
					<div>
						<div>{record.name}</div>
						<div>{record.phone}</div>
					</div>
				)
			},
			{
				title: "年级",
				dataIndex: "grade",
				key: "grade"
				// render: record => (
				// 	<span
				// 		onClick={() => {
				// 			this.showDetail(record.grade);
				// 		}}
				// 	>
				// 		{record.attendanceNum || null}
				// 	</span>
				// )
			},
			{
				title: "班级",
				dataIndex: "class",
				key: "class"
				// render: record => (
				// 	<span
				// 		onClick={() => {
				// 			this.showDetail(record.grade);
				// 		}}
				// 	>
				// 		{record.class || null}
				// 	</span>
				// )
			}
		];
	}

	componentDidMount() {
		// console.log(this.state.info);
		// let date = moment(new Date())
		// 	.format(dateFormat)
		// 	.replace(/\//g, "-");
		let date = new Date().getTime() + "";
		this.setState({
			dateNow: date
		});
		this.initData(date);
	}
	initData(date) {
		const schoolId = getStore("schoolId");
		let params = {
			schoolId: schoolId
		};
		const { id } = getParams();
		let schoolParams = { schoolId: schoolId, date: date || null };
		let param = {
			pageNo: 1,
			pageSize: 10,
			studentName: "",
			gradeId: "",
			classId: "",
			date: date
		};
		this.getSchoolAttendence(schoolParams);
		this.getScanRecord(param);
		this.getGradeList(params);
	}
	getScanRecord(data) {
		Api.getScanRecord(data).then(({ res }) => {
			let { data, total } = res;
			let record = [];
			data.forEach((el, ind) => {
				// console.log("res", moment(el.dayTime).format(dateFormat));
				record.push({
					time: {
						date: moment(el.dayTime).format(dateFormat),
						time: moment(el.hourTime).format(timeFormat)
					},
					name: {
						name: `${el.studentName}${el.relationName}`,
						phone: el.parentPhone
					},
					grade: el.gradeName,
					class: el.className
				});
			});
			this.setState({
				record: record,
				total: total
			});
		});
	}
	getSchoolAttendence(params, index) {
		try {
			Api.getSchoolAttendence(params)
				.then(({ res }) => {
					let { data } = res;
					this.setState({
						attendance: data
					});
					this.handleAttendance(data.checkCountList);
					// console.log(this.state.attendance);
				})
				.catch(e => {
					// console.log("school", e);
				});
		} catch (error) {
			// console.log("scccc", error);
		}
	}
	getGradeList(params, index) {
		Api.getGradeList(params).then(({ res }) => {
			const { data } = res;
			this.setState({
				data: data
			});
			this.handleSetData(data);
			if (this.state.classIndex) {
				this.onChange(this.state.classIndex);
			}
		});
	}
	handleShowModal = () => {
		this.setState({
			showModal: true
		});
	};
	showDetail(id) {
		let params = { classId: id };
		Api.getClassAttendence(params).then(({ res }) => {
			const { data } = res;
			this.setState({
				detailList: data.oneStudentCheckInfoList
			});
			// console.log("APi".this.state.detailList);
		});
		this.state.showDetail = true;
	}
	outportList = values => {
		values["schoolId"] = getStore("schoolId");
		Api.addGrade(values).then(({ res }) => {
			const { success } = res;
			if (success) {
				message.success("操作成功！");
				this.setState(
					{
						showModal: false
					}
					// () => {
					//   this.handleGradeList()
					// },
				);
			}
		});
	};
	onChange(date, dateString) {
		console.log(date, this.state.data);
		let list = [];
		this.state.data.map((el, index) => {
			if (index === date) {
				el.classList.forEach((e, i) => {
					let idObj = {
						classId: e.classId,
						schoolId: e.schoolId,
						gradeId: e.gradeId
					};
					let obj = {
						name: `${e.gradeName} ${e.name}`,
						id: JSON.stringify(idObj),
						teacherName: e.teacherName,
						classId: e.classId
					};
					list.push(obj);
				});

				this.setState({
					classList: list,
					classIndex: date
				});
				// console.log(el.classList);
			}
		});
		// console.log(this.state.classList);
	}
	handleSetData = data => {
		let lists = [];
		let grade = [];
		data.forEach((item, index) => {
			let children = [];
			if (item.classList.length > 0) {
				item.classList.map((el, ind) => {
					children.push({ label: el.name, value: el.classId });
					let idObj = {
						classId: el.classId,
						schoolId: el.schoolId,
						gradeId: el.gradeId
					};
					// let classParams = {classId: el.classId}
					// Api.getClassAttendence(classParams).then(({res}) => {
					//   console.log('classs', res)
					// })
					let obj = {
						name: `${el.gradeName} ${el.name}`,
						id: JSON.stringify(idObj),
						classId: el.classId,
						teacherName: el.teacherName
					};
					if (index === 0) {
						lists.push(obj);
					}
				});
			}
			grade.push({ label: item.name, value: item.gradeId, children });

			// this.setState({

			// });
		});
		setTimeout(() => {
			// console.log(this.state.classList);
		}, 0);
		// console.log(data);
		this.setState({
			classList: lists,
			gradeList: grade,
			grade: grade
		});
		return grade;
	};
	handleAttendance(params) {
		let attendanceData = {};
		params.forEach((el, index) => {
			attendanceData[el.classId] = {
				totalNo: el.totalNo,
				checkedNo: el.checkedNo,
				lateNo: el.lateNo,
				leaveNo: el.leaveNo
			};
		});
		this.setState({
			attendanceData: attendanceData
		});
	}
	disabledDate = current => {
		// return current < moment(new Date('2018/07/15')) || current > moment().endOf('day')
		return current > moment().endOf("day");
	};
	chooseDate(date, dateString) {
		let dateStr = new Date(dateString.replace(/\//g, "-")).getTime() + "";
		if (isNaN(dateStr)) {
			dateStr = "";
		}
		this.setState({
			dateNow: dateStr
		});
		const { pages, name } = this.state;
		let param = {
			pageNo: 1,
			pageSize: 10,
			studentName: this.state.name,
			gradeId: this.state.gradeName,
			classId: this.state.className,
			date: dateStr
		};
		this.getScanRecord(param);
		// this.initData(dateStr);
	}
	handleCancel() {
		this.setState({
			showDetail: false,
			showModal: false
		});
	}
	formatData = () => {
		const adders = [];
		for (let province in addressData) {
			const label = addressData[province].name;
			const city = addressData[province].city;
			const children = [];
			for (let n = 0, len = city.length; n < len; n++) {
				let areaArr = [];
				city[n].area.forEach(el => {
					areaArr.push({ value: el, label: el });
				});
				let cityList = {
					label: city[n].name,
					value: `${city[n].name}`,
					// children: []
					children: areaArr
				};
				if (city[n].name === "其他") {
					cityList["value"] = `a-${city[n].name}`;
				}
				children.push(cityList);
			}
			const data = {
				label,
				value: label,
				children
			};
			adders.push(data);
		}
		return adders;
	};
	onChangeCity = val => {
		console.log("val", val);
		this.setState(
			{
				gradeName: val[0],
				className: val[1]
			},
			this.handleSearch({ gradeName: val[0], className: val[1] })
		);
	};
	handleChangeName = e => {
		e.target.value = e.target.value.replace(/[`！!~@#\$%\^&\*\d]/g, "");
		console.log(e.target.value);
		this.setState({
			name: e.target.value
		});
	};
	handleSearch = val => {
		let { gradeName, className } = val;
		let param = {
			pageNo: 1,
			pageSize: 10,
			studentName: this.state.name,
			gradeId: gradeName || this.state.gradeName,
			classId: className || this.state.className,
			date: this.state.dateNow
		};
		this.getScanRecord(param);
	};
	pageChange = pagination => {
		this.setState({
			currentPage: pagination
		});
		const params = {
			Current: pagination,
			Size: 10
		};
		this.getScanRecord(params);
	};
	render() {
		const { showDetail, grade, record, name, currentPage, total } = this.state;
		const paginationProps = {
			page: currentPage,
			onChange: page => this.pageChange(page),
			total: total
		};
		console.log("-------", record);
		let classArr = [];
		this.state.classList.forEach((element, index) => {
			let { classId } = element;
			if (this.state.attendanceData[classId]) {
				element.attendanceNum = `${
					this.state.attendanceData[classId].checkedNo
				}/${this.state.attendanceData[classId].totalNo}`;
				element.late = this.state.attendanceData[classId].lateNo;
				element.leave = this.state.attendanceData[classId].leaveNo;
			}
			classArr.push(element);
		});
		return (
			<div>
				<div className={`${styles.top}`}>
					<Card>
						<p>扫码记录</p>
						<div className={styles.chosen}>
							<DatePicker
								defaultValue={moment(new Date(), dateFormat)}
								format={dateFormat}
								onChange={this.chooseDate.bind(this)}
								disabledDate={this.disabledDate}
							/>
						</div>
					</Card>
				</div>
				<div className={`${styles.top}`}>
					<Card>
						<div className={styles.searchArea}>
							{/* <Dropdown
          overlay={menu}
          trigger={["hover"]}
          // className={`${styles.chosen}`}
        >
          <a className="ant-dropdown-link" href="javascript:void(0)">
            选择班级 <Icon type="down" />
          </a>
        </Dropdown> */}
							<Input
								value={name}
								placeholder="请输入姓名"
								autoComplete="off"
								className={styles.search}
								onChange={this.handleChangeName}
							/>
							<Button
								onClick={this.handleSearch}
								type="primary"
								style={{ marginLeft: "20px", marginRight: "40px" }}
							>
								搜索
							</Button>
							{grade.length ? (
								<Cascader
									style={{ width: "200px" }}
									// value={[addressObj.province, addressObj.city, addressObj.area]}
									onChange={value => {
										this.onChangeCity(value);
									}}
									// options={options}
									options={grade}
									placeholder="请选择年级/班级"
								/>
							) : null}
							{/* <Select
							showSearch
							style={{ width: 200, marginBottom: "20px" }}
							placeholder="请选择年级"
							optionFilterProp="children"
							onChange={this.onChange.bind(this)}
							defaultValue={0}
							filterOption={(input, option) =>
								option.props.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
						>
							{this.state.gradeList.map((child, index) => {
								return (
									<Option key={index} value={index}>
										{child.grade}
									</Option>
								);
							})}
						</Select> */}
							{/* <DatePicker
            onChange={onChange}
            defaultValue={moment('2015/01/01', dateFormat)}
            format={dateFormat}
          /> */}
							{/* <Button className={`${styles.searchBtn}`} icon={"search"} /> */}
						</div>

						<Table
							columns={this.columns}
							dataSource={record}
							onChange={this.pageChange}
							pagination={paginationProps}
							id="table"
						/>
					</Card>
				</div>
				{
					<Modal
						title="未入园"
						visible={showDetail}
						onOk={this.handleOk}
						onCancel={this.handleCancel.bind(this)}
					>
						{this.state.detailList.map(item => {
							return <p>{item.name}</p>;
						})}
					</Modal>
				}
			</div>
		);
	}
}
