const homeCreate = (ajaxinstance) => {
  const home = {}

  // 登录接口
  home.login = (postData) => (
    ajaxinstance.post('/attendance/school/login', postData)
  )

  return home
}

export default homeCreate
