import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Row, Col, message } from 'antd'
import styles from './password'
import md5 from 'md5'

import Api from '@/common/api'

const FormItem = Form.Item

class FindPassword extends Component {
  constructor () {
    super()
    this.state = {
      phone: '',
      type: '1',
      code: '',
      count: 59,
      liked: true
    }
  }
  // 获取验证码
  getYanzCode = () => {
    let { count } = this.state
    const { phone } = this.state
    if (!(/^1[3-9]\d{9}$/.test(phone))) {
      message.error('格式有误，请输入正确的手机号！')
    } else {
      Api.sendCode({ phone }).then(({ res }) => {
        const { success } = res
        if (success) {
          // 倒计时
          const timer = setInterval(() => {
            this.setState({ count: (count--), liked: false }, () => {
              if (count === 0) {
                clearInterval(timer)
                this.setState({
                  liked: true,
                  count: 60
                })
              }
            })
          }, 1000)
        }
      })
    }
  }

  // 验证验证码
  handleCheckCode = () => {
    const { phone, code } = this.state
    if (!(/^1[3-9]\d{9}$/.test(phone))) {
      message.error('格式有误，请输入正确的手机号！')
      return false
    }
    Api.checkCode({ phone, code }).then(({ res }) => {
      const { success } = res
      if (success) {
        this.setState({
          type: '2'
        })
      }
    })
  }
  // 修改密码
  handleSubmit = () => {
    const { phone } = this.state
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values['phone'] = phone
        values['confirm'] = ''
        Api.updatePasswd({ phone : values.phone, password : md5(values.password) }).then(({ res }) => {
          const { success } = res
          if (success) {
            message.success('修改成功！', [3], () => {
              this.props.history.push('/login')
            })
          }
        })
      }
    })
  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码保持一致!')
    } else {
      callback()
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  };

  handelChangePhone = (e) => {
    this.setState({
      phone: e.target.value
    })
  }
  handelChangeCode = (e) => {
    this.setState({
      code: e.target.value
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    }
    const { type, liked, count } = this.state
    return (
      <div className={styles.findCont}>
        <div className={styles.header}>
          <div className={styles.headerCenter}>
            <div className={styles.content}>
              <div className={styles.left}>
                <img src='' alt='' />
                <span className={styles.welcomeCommon}>ISUS公众平台</span>
                <span className={styles.welcomeTitle}>|</span>
                <span className={styles.welcomeCommon}>校服厂</span>
              </div>
            </div>
          </div>
        </div>
        <p className={styles.title}>
          {
            type === '1' ? '找回密码' : '重设密码'
          }
        </p>
        <div className={styles.container}>
          <div className={styles.formContainer}>
            {
              type === '1'
                ? <Form>
                  <FormItem className='firstFormItem'
                    {...formItemLayout}
                    label='手机号码'
                  >
                    {getFieldDecorator('phone', {
                      rules: [{
                        required: true, message: '请输入手机号码!'
                      }, {
                        validator: this.validateToTelphone
                      }]
                    })(
                      <div className='common_form'>
                        <Input type='text' onInput={this.handelChangePhone} placeholder='请输入手机号' />
                      </div>
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label='验证码'
                  >
                    <Row gutter={8}>
                      <Col span={18}>
                        {getFieldDecorator('captcha', {
                          rules: [{ required: true, message: '请输入验证码!' }]
                        })(
                          <div className={styles.inputSms}>
                            <Input onInput={this.handelChangeCode} type='text' placeholder='请输入验证码' />
                            <div className={styles.smsInput}>
                              <span className={styles.smsDevide}>|</span>
                              {
                                liked
                                  ? <span onClick={this.getYanzCode} className={styles.smsBomb} >获取验证码</span>
                                  : <span >{count + 's后重试'}</span>
                              }
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <Button type='primary' htmlType='submit' onClick={this.handleCheckCode}>下一步</Button>
                  </FormItem>
                </Form>
                : <Form>
                  <FormItem className='firstFormItem'
                    {...formItemLayout}
                    label='新密码'
                  >
                    {getFieldDecorator('password', {
                      rules: [{
                        required: true, message: '请输入新密码!',
                      }, {
                        validator: this.validateToNextPassword
                      }, {
                        minLength: 6, message: '至少6位'
                      }]
                    })(
                      <div className='common_form'>
                        <Input type='password' placeholder='请输入新密码' />
                      </div>
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label='确认新密码'
                  >
                    <Row gutter={8}>
                      <Col span={18}>
                        {getFieldDecorator('passwordAgain', {
                          rules: [{ required: true, message: '请输入验证码!' },
                            { validator: this.compareToFirstPassword }, ]
                        })(
                          <Input type='password' placeholder='两次密码保持一致' />
                        )}
                      </Col>
                    </Row>
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <p>密码由6~20位字母（区分大小写）、数字或符号组成</p>
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Form>
            }

          </div>
        </div>
      </div>
    )
  }
}

FindPassword.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object
}

const FindPasswordForm = Form.create()(FindPassword)
export default FindPasswordForm
