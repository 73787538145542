import React from 'react'
import { Button, Card, DatePicker, Menu, Statistic, Table } from 'antd'
import styles from './studentAttendance'
import moment from 'moment'
import Api from '@/common/api'
// import { RangerPicker } from DatePicker;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY/MM/DD'
const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.alipay.com/"
      >
        一年(1)班
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.taobao.com/"
      >
        二年(1)班
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        三年(1)班
      </a>
    </Menu.Item>
  </Menu>
)

const columns = [
  {
    title: '日期',
    dataIndex: 'date'
  },
  {
    title: '考勤记录',
    dataIndex: 'record',
    key: 'record'
  },
  {
    title: '状态',
    render: record => {
      switch (record.status) {
        case '0':
          return '未入园'
        case '1':
          return '已入园'
        case '2':
          return '迟到'
        case '3':
          return '请假'
        case '4':
          return '不用考勤'
      }
    }
  }
]

export default class StudentAttendance extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      attendedNum: 0,
      totalNum: 0,
      info: { num: 0, total: 0, late: 0, leave: 0 },
      class: {},
      studentId: null,
      studentData: {},
      data: [],
      fromDate: moment(new Date(), dateFormat)
    }
  }

  componentDidMount () {
    const studentId = this.getParams(this.props.location.search).id
    this.setState({
      studentId: studentId
    })
    // console.log(studentId)
    let params = { studentId: studentId }
    this.getStudentList(params)
    // this.setState({classId: classId})
    // console.log(this.state.info)
    // let params = {
    //   classId: classId,
    // }
    // const {id} = getParams()
    // console.log(params)
    // this.getClassList(params)
  }

  getStudentList (params) {
    Api.getStudentAttendence(params).then(({ res }) => {
      const { data } = res
      let arr = []
      data.oneDayCheckInfoList.forEach((el, index) => {
        let str = (el.checkLogList || []).map(e => e.hourTime).join(' ')
        arr.push({
          key: index,
          date: moment(el.date).format('MM-DD'),
          status: el.status,
          record: str
        })
      })
      this.setState({
        studentData: data,
        data: arr
      })
      // this.handleSetData(data.oneStudentCheckInfoList)
    })
  }

  getParams (str) {
    let Arr = str.replace('?', '').split('&')
    let Obj = {}
    Arr.forEach((element, index) => {
      let paramArr = element.split('=')
      Obj[paramArr[0]] = decodeURI(paramArr[1])
    })
    return Obj
  }

  onChange (date, dateString) {
    let from = dateString[0].replace(/\//g, '-')
    let to = dateString[1].replace(/\//g, '-')
    let params = {
      studentId: this.state.studentId,
      from: from,
      to: to
    }
    this.getStudentList(params)
  }

  getMonthData () {
    const to = moment(new Date())
      .format(dateFormat)
      .replace(/\//g, '-')
    const fromDate = moment(
      new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    ).format(dateFormat)
    const from = fromDate.replace(/\//g, '-')
    this.setState({
      fromDate: moment(
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        dateFormat
      )
    })
    let params = {
      studentId: this.state.studentId,
      from: from,
      to: to
    }
    this.getStudentList(params)
    // console.log(111)
  }

  render () {
    let { data, fromDate } = this.state
    return (
      <div>
        <div className={`${styles.top}`}>
          <Card>
            <h1>{this.state.studentData.studentName || ''}的考勤记录</h1>
            <p>
              全校考勤/
              {`${this.state.studentData.gradeName} ${
                this.state.studentData.className
              }`}
              /{this.state.studentData.studentName}
            </p>
          </Card>
        </div>
        <div className={`${styles.top} ${fromDate}`}>
          <RangePicker
            onChange={this.onChange.bind(this)}
            defaultValue={[fromDate, moment(new Date(), dateFormat)]}
            format={dateFormat}
          />
          <Button
            className={`${styles.btn}`}
            onClick={this.getMonthData.bind(this)}
          >
            近一个月
          </Button>
          <Card style={{ marginTop: '20px' }}>
            <Table columns={columns} dataSource={data} id="table"/>
          </Card>
        </div>
      </div>
    )
  }
}

function Statistics (props) {
  // console.log('props', props)
  return (
    <div className={`${styles.top}`}>
      <Card>
        {/* <Dropdown
            overlay={menu}
            trigger={["hover"]}
            className={`${styles.chosen}`}
          >
            <a className="ant-dropdown-link" href="javascript:void(0)">
              选择班级 <Icon type="down" />
            </a>
          </Dropdown> */}
        {/* <DatePicker
            onChange={onChange}
            defaultValue={moment('2015/01/01', dateFormat)}
            format={dateFormat}
          /> */}
        <DatePicker
          defaultValue={moment(new Date(), dateFormat)}
          onChange={onChange}
          format={dateFormat}
        />
        <div className={`${styles.statisticsNum} ${styles.chosen}`}>
          {/* <span></span> */}

          <Statistic
            title="考勤人数/全班人数："
            value={props.info.num}
            suffix={`/${props.info.total}`}
          />
        </div>
        <div className={`${styles.statisticsNum}`}>
          <span>迟到：</span>
          {props.info.late}
        </div>
        <div className={`${styles.statisticsNum}`}>
          <span>请假：</span>
          {props.info.leave}
        </div>
      </Card>
    </div>
  )
}
