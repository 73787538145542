import React from 'react'
import { Button } from 'antd'
class GuideHome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    return (
      <div className={``}>
        欢迎使用钦家智能校服管理平台
        <Button type='primary'>立即开启</Button>
      </div>
    )
  }
}

export default GuideHome
