// 环境
let env = IS_ENV === 'production' || IS_ENV === 'pre' ? '' : 'test'
//let env = IS_ENV

// 接口host
//const baseHost = 'https://prod-admin.mm-iworld.com/'
//const baseHost = `http://${IS_ENV === 'production' || IS_ENV === 'pre' ? 'prod' : 'dev'}.mm-iworld.com/`
// const baseHost = `https://${IS_ENV === 'production' || IS_ENV === 'pre' ? 'prod' : 'dev'}.mm-iworld.com/`
const baseHost = 'https://dev.mm-iworld.com/'
//const baseHost = '/api'
//const baseHost = 'http://dev.mm-iworld.com/'
// const baseHost = `/api`
const host = baseHost
// sso url
const ssoUrl = `${env}ecm.dnatime.com/#/login`

export default {
  baseHost,
  host,
  ssoUrl,
}
