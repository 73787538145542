import React from 'react'
import { Route } from 'react-router-dom'

import RuleConfig from './ruleConfig'
import RuleList from './ruleList'

export default (
  <React.Fragment>
    <Route path='/ruleConfig' component={RuleConfig} />
    <Route path='/ruleList' component={RuleList} />
  </React.Fragment>
)
