const attendenceCreate = ajaxinstance => {
  const attendenceAPI = {}

  // 学校考勤
  attendenceAPI.getSchoolAttendence = data =>
    ajaxinstance.post('/attendance/check/count/school', data)
  // 班级考勤
  attendenceAPI.getClassAttendence = data =>
    ajaxinstance.post('/attendance/check/count/class', data)
  // 学生考勤
  attendenceAPI.getStudentAttendence = data =>
    ajaxinstance.post('/attendance/check/count/student', data)
  // 导入学生
  attendenceAPI.importStudent = postData =>
    ajaxinstance.post('/attendance/student/import', postData)
  // 导入老师
  attendenceAPI.importTeacher = postData =>
    ajaxinstance.post('/attendance/teacher/import', postData)
  // 获取考勤规则列表
  attendenceAPI.getRuleListAll = postData =>
    ajaxinstance.post('/attendance/checkRule/list', postData)
  // 获取考勤规则
  attendenceAPI.getRuleList = postData =>
    ajaxinstance.post('/attendance/checkRule/get', postData)
  // 配置考勤规则
  attendenceAPI.getRuleSave = postData =>
    ajaxinstance.post('/attendance/checkRule/save', postData)
  attendenceAPI.detaleRule = postData =>
    ajaxinstance.post('/attendance/checkRule/delete', postData)
  return attendenceAPI
}

export default attendenceCreate
