import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, Button, message, Radio, DatePicker, InputNumber } from 'antd'
import moment from 'moment'
import Api from '@/common/api'
import { getParams } from '@/common/utils/rowkey'
import { getStore } from '@/common/utils/localstore'
const FormItem = Form.Item
const { TextArea } = Input
const { RangePicker } = DatePicker

class AddStudentInfoForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      studentDetail: {},
      isEdit: false,
      startTime: '',
      endTime: '',
      liveStart: ''
    }
  }

  componentDidMount () {
    const { id } = getParams()
    if (id) { // 编辑
      this.setState({
        isEdit: true
      })
      this.enrollmentDetail(id)
    }
  }
  enrollmentDetail = (id) => {
    Api.enrollmentDetail({ id }).then(({ res }) => {
      const { data } = res
      data.endTime = data.endTime ? new Date(data.endTime) : null
      data.startTime = data.startTime ? new Date(data.startTime) : null
      data.liveStart = data.liveStart ? new Date(data.liveStart) : null
      this.setState({
        studentDetail: data,
        liveStart: data.liveStart ? new Date(data.liveStart) : null
      })
    })
  }
  handleCreate = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleSaveEnrollmentInfo(values)
      }
    })
  }
  handleCancle = () => {
    this.props.history.push('/studentsInfo')
  }
  handleSaveEnrollmentInfo = (values) => {
    const { studentDetail, isEdit, startTime, endTime, liveStart } = this.state
    let params = {
      ...values,
      enrollmentId: isEdit ? studentDetail.enrollmentId : '',
      schoolId: getStore('schoolId'),
      startTime: startTime || studentDetail.startTime,
      endTime: endTime || studentDetail.endTime,
      liveStart: liveStart || studentDetail.liveStart,
      price: values.price ? values.price : 0
    }
    if ((params.liveType === '1' && !liveStart && !studentDetail.liveStart)) {
      message.error('请选择生效时间')
      return false
    }
    Api.enrollmentSave(params).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功')
        this.props.history.push('/studentsInfo')
      }
    })
  }
  onChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0],
      endTime: dateString[1]
    })
  }
  onChangeDate = (date, dateString) => {
    this.setState({
      liveStart: dateString,
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 12
      },
    }
    const dateFormat = 'YYYY-MM-DD'
    const { getFieldDecorator } = this.props.form
    const { studentDetail, liveStart } = this.state
    return (
      <div style={{ paddingTop: '20px' }}>
        <Form>
          <FormItem label='招生对象' {...formItemLayout}>
            {
              getFieldDecorator('target', {
                initialValue: studentDetail.target || '',
                rules: [
                  { required: true, message: '请输入招生对象' }
                ]
              })(
                <Input placeholder='例如：3-6岁的小朋友' maxLength={20} autoComplete='off' />
              )
            }
          </FormItem>
          <Form.Item label='招生人数' {...formItemLayout}>
            {getFieldDecorator('no', {
              initialValue: studentDetail.no || '',
              rules: [
                { required: true, message: '请输入招生人数' },
                {
                  pattern: /^\+?[1-9]\d*$/, message: '请输入整数'
                }
              ]
            })(
              <InputNumber style={{ width: '100%' }} placeholder='请输入招生人数' maxLength={20} autoComplete='off' />
            )}
          </Form.Item>
          <FormItem {...formItemLayout} label='学费'>
            {getFieldDecorator('price', {
              initialValue: studentDetail.price || '',
              rules: [
                {
                  pattern: /^\+?[1-9]\d*$/, message: '请输入整数'
                }
              ]
            })(
              <InputNumber
                style={{ width: '100%' }}
                placeholder='请输入学费'
                maxLength={20}
                autoComplete='off'
              />
            )}
          </FormItem>
          <FormItem label='报名时间' {...formItemLayout}>
            {
              getFieldDecorator('time', {
                initialValue: studentDetail.startTime
                  ? [moment(studentDetail.startTime, dateFormat), moment(studentDetail.endTime, dateFormat)]
                  : null,
                rules: [
                  { required: true, message: '请输入报名开始时间!' }
                ]
              })(
                <RangePicker onChange={this.onChange} />
              )
            }
          </FormItem>
          <Form.Item label='招生计划' {...formItemLayout}>
            {getFieldDecorator('plan', {
              initialValue: studentDetail.plan,
            })(
              <TextArea rows={8} maxLength={500} placeholder='请输入招生计划，限500字' />
            )}
          </Form.Item>
          <Form.Item label='优惠政策' {...formItemLayout}>
            {getFieldDecorator('rule', {
              initialValue: studentDetail.rule,
            })(
              <TextArea rows={8} maxLength={500} placeholder='请输入优惠政策，限500字' />
            )}
          </Form.Item>
          <FormItem label='报名所需资料' {...formItemLayout}>
            {
              getFieldDecorator('command', {
                initialValue: studentDetail.command,
              })(
                <TextArea rows={8} maxLength={500} placeholder='请输入报名所需资料，限500字' />
              )
            }
          </FormItem>
          <FormItem label='报名咨询老师' {...formItemLayout}>
            {
              getFieldDecorator('contact', {
                initialValue: studentDetail.contact,
              })(
                <Input placeholder='请输入报名咨询老师' maxLength={20} autoComplete='off' />
              )
            }
          </FormItem>
          <FormItem label='报名咨询电话' {...formItemLayout}>
            {
              getFieldDecorator('phone', {
                initialValue: studentDetail.phone,
              })(
                <Input placeholder='请输入报名咨询电话' maxLength={11} autoComplete='off' />
              )
            }
          </FormItem>
          <FormItem label='生效时间' {...formItemLayout}>
            {
              getFieldDecorator('liveType', {
                initialValue:   studentDetail.liveType || '0',
              })(
                <Radio.Group>
                  <Radio value='0'>立即生效</Radio>
                  <Radio value='1'>
                    生效时间
                    <DatePicker onChange={this.onChangeDate} value={liveStart ? moment(liveStart, dateFormat) : ''} />
                  </Radio>
                </Radio.Group>
              )
            }
          </FormItem>
        </Form>
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <Button
            type='primary'
            onClick={this.handleCreate}
            style={{ marginRight: '20px' }}
          >
            保存
          </Button>
          <Button onClick={this.handleCancle}>取消</Button>
        </div>
      </div>
    )
  }
}
AddStudentInfoForm.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object.isRequired
}

const AddStudentInfo = Form.create()(AddStudentInfoForm)

export default AddStudentInfo
