// 获取url参数
export const getParams = str => {
  const obj = {}
  const hash = location.hash
  const url = hash.substring(hash.lastIndexOf('?'))
  str = str || url
  const params = str.replace(/\?/, '')
  const arry = params.split('&')
  if (!arry.length) {
    return {}
  }
  arry.forEach((item, i) => {
    let key = item.split('=')
    obj[key[0]] = key[1]
  })
  return obj
}
