// user reducer

const initState = {
  loading:false,
  userName:'',
}

export function user (state = initState, action) {
  switch (action.type) {
    case 'ADD_USERNAME':
      return {
        ...state,
        userName:'test user',
      }
    case 'LOADING':
      return {
        ...state,
        loading:!state.loading,
      }
    default:
      return state
  }
}
