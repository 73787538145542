import axios from 'axios'
import { message } from 'antd'
import config from '../utils/config'
import { loading } from '@/store/reducers/mainReducer'
// 接口模块
import home from './home'
import user from './user'
import teacher from './teacher'
import notice from './notice'
import classApi from './class'
import attendenceApi from './attendence'
import schoolApi from './schoolManage'
import scanCode from './security'

const { host } = config
const loadinList = []

// 实例化 ajax请求对象
const ajaxinstance = axios.create({
  baseURL: host,
  timeout: 60000,
  headers: {
    // responseType: 'json',
    // 'Access-Control-Allow-Headers': '*'
  }
})

// 请求拦截器
ajaxinstance.interceptors.request.use(
  request => {
    const { url } = request
    const token = localStorage.getItem('token')
    const { data = {}, params = {} } = request
    // 如果是发送手机验证码，验证验证码，修改密码，不需要token
    if (
      url === 'attendance/school/edit/password' ||
      url === 'attendance/check/code' ||
      url === 'attendance/send/code'
    ) {
    } else {
      token || (window.location.href = '/#/login')
      token && (request.headers['Authorization'] = token)
    }
    if (!data.noloading && !params.noloading) {
      loading.show()
      loadinList.push(1)
    }
    return request
  },
  error => {
    loadinList.splice(0, 1)
    loading.hide()
    Promise.reject(error)
  }
)

// 响应拦截器
ajaxinstance.interceptors.response.use(
  response => {
    const { success, message: msg, code } = response.data
    loadinList.splice(0, 1)
    loadinList.length || loading.hide()
    if (success) {
      return { res: response.data }
    } else {
      if (code === 'TK05') {
        window.location.href = '/#/login'
      } else {
        message.error(msg || '服务器繁忙！！！')
      }
    }
  },
  error => {
    loadinList.splice(0, 1)
    loading.hide()
    return Promise.reject(error)
  }
)

/**
 * [API api接口封装]
 * @type {Object}
 */
const API = {
  ...home(ajaxinstance),
  ...teacher(ajaxinstance),
  ...user(ajaxinstance),
  ...notice(ajaxinstance),
  ...classApi(ajaxinstance),
  ...attendenceApi(ajaxinstance),
  ...schoolApi(ajaxinstance),
  ...scanCode(ajaxinstance)
}

export default API
