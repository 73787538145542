const regExps = {}

// 非零的正整数
regExps.number = /^\+?[1-9][0-9]*$/
// 金额(最多两位小数)
regExps.price = /^(([1-9]\d*)(\.\d{1,2})?)$|(0\.0?([1-9]\d?))$/

export default {
  ...regExps
}
