import React from 'react'
import styles from './guide'
import { Steps } from 'antd'
import GuideHome from './components/home'

const { Step } = Steps
const steps = [
  {
    title: '欢迎',
    content: GuideHome,
  },
  {
    title: '创建班级',
    content: 'Second-content',
  },
  {
    title: '创建老师',
    content: 'Third-content',
  },
  {
    title: '设置考勤规则',
    content: 'Fourth-content',
  },
  {
    title: '完善学校信息',
    content: 'Last-content',
  },
]
class GuidePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: 0,
    }
  }

  componentDidMount () {
  }
  next = (index) => {
    this.setState({ current: index })
  }

  render () {
    const { current } = this.state
    return (
      <div className={styles.guideCont}>
        <div className={styles.stepsCont}>
          <Steps current={current}>
            {
              steps.map((item, index) => (
                <Step onClick={() => this.next(index)} key={item.title} title={item.title} />
              ))
            }
          </Steps>
          <div className={styles.stepsContent}>
            {
              steps[current].content
            }
          </div>
        </div>

      </div>
    )
  }
}

export default GuidePage
