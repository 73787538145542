import React from 'react'
import { Route } from 'react-router-dom'

import NoticeList from './noticeList' // 通知列表
import AddNotice from './addNotice' // 发布通知

export default (
  <React.Fragment>
    <Route path='/noticeList' component={NoticeList} />
    <Route path='/addNotice' component={AddNotice} />
  </React.Fragment>
)
