import React from 'react'
import {Route} from 'react-router-dom'

import TeacherList from './teacherList' // 教师列表
import AddTeacher from './addTeacher' // 添加教师

export default (
  <React.Fragment>
    <Route path="/teacherList" component={TeacherList} />
    <Route path="/addTeacher" component={AddTeacher} />
  </React.Fragment>
)
