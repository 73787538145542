const userCreate = (ajaxinstance) => {
  const user = {}

  // 忘记学校密码
  user.updatePasswd = (postData) => (
    ajaxinstance.post('attendance/school/edit/password', postData)
  )
  // 获取验证码
  user.sendCode = (postData) => (
    ajaxinstance.post('attendance/send/code', postData)
  )

  // 验证验证码
  user.checkCode = (postData) => (
    ajaxinstance.post('attendance/check/code', postData)
  )

  // 校验原密码
  user.checkOldPassword = (postData) => (
    ajaxinstance.post('attendance/school/check/oldPassword', postData)
  )
  // 修改密码
  user.changePassword = (postData) => (
    ajaxinstance.post('attendance/school/change/password', postData)
  )
  return user
}

export default userCreate
