import qs from 'qs'
import config from './config'

// 导出
export const exportExcel = (url, data, type) => {
  const href = config.host + url
  const iframe = document.createElement('iframe')
  // const accessToken = window.localStorage.getItem('token')
  iframe.style.display = 'none'
  iframe.src = href + '?' + qs.stringify(data) // + '&token=' + accessToken

  document.body.appendChild(iframe)
  iframe.onload = function () {
    document.body.removeChild(iframe)
  }
}
