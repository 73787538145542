import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select } from 'antd'

import { getStore } from '@/common/utils/localstore'
import Api from '@/common/api'

const FormItem = Form.Item
const { Option } = Select

class ChangeTeacherModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teacherList: [],
      teacherName: ''
    }
  }

  componentDidMount () {
    this.handleTeacherList()
  }

  handleConfirm = () => {
    const { detailInfo } = this.props
    const { teacherName } = this.state
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values['classId'] = detailInfo.classId
        values['teacherName'] = teacherName
        this.props.onOk(values)
      }
    })
  }

  handleCancel = (e) => {
    this.props.onCancel()
  }

  // 获取教师列表
  handleTeacherList = () => {
    const params = {
      schoolId: getStore('schoolId'),
      name: '',
      pageNo: 1,
      pageSize: 999
    }
    Api.queryTeacherList(params).then(({ res }) => {
      const { data, success } = res
      if (success) {
        this.setState({
          teacherList: data
        })
      }
    })
  }

  handleChange = (teacherName) => {
    this.setState({
      teacherName
    })
  }

  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      },
    }
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { teacherList } = this.state
    return (
      <div>
        <Modal
          visible
          maskClosable={false}
          title={'更换班主任'}
          onCancel={this.handleCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='原班主任' {...formItemLayout}>
                {
                  getFieldDecorator('teacherName', {
                    initialValue: detailInfo.teacherName || '暂无班主任',
                  })(
                    <Input style={{ border: 0 }} readOnly autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='新班主任' {...formItemLayout}>
                {
                  getFieldDecorator('teacherId', {
                    // initialValue: detailInfo.channelCategory,
                    rules: [
                      { required: true, message: '请选择班主任!' }
                    ]
                  })(
                    <Select style={{ width: '100%' }}>
                      {
                        teacherList.length > 0
                          ? teacherList.map((item, index) => {
                            return (<Option key={index}
                              onClick={() => this.handleChange(item.teacherName)}
                              value={item.teacherId}>{item.teacherName}</Option>)
                          })
                          : null
                      }
                    </Select>
                  )
                }
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

ChangeTeacherModalForm.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
  detailInfo: PropTypes.object
}

const ChangeTeacherModal = Form.create()(ChangeTeacherModalForm)

export default ChangeTeacherModal
