import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, Button, message, Radio, DatePicker, Row, Col, Checkbox } from 'antd'
import moment from 'moment'
import Api from '@/common/api'
import { getParams } from '@/common/utils/rowkey'
import { getStore } from '@/common/utils/localstore'
const FormItem = Form.Item

class AddTeacherForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teacherDetail: {},
      isEdit: false,
      classList: [],
      classes: []
    }
  }

  componentDidMount () {
    const { id } = getParams()
    this.handleGradeList()
    if (id) { // 编辑
      this.setState({
        isEdit: true
      })
      this.getTeacherInfo(id)
    }
  }
  // 获取年级列表和班级列表
  handleGradeList = () => {
    let params = {
      schoolId: getStore('schoolId')
    }
    Api.getGradeList(params).then(({ res }) => {
      const { data } = res
      this.handleSetData(data)
    })
  }
  getTeacherInfo = (id) => {
    Api.getTeacherInfo({ id }).then(({ res }) => {
      const { data } = res
      data.birth = data.birth ? new Date(data.birth) : null
      let classes = []
      data.classList.map((item, index) => {
        let obj = {
          classId: item.classId,
          schoolId: item.schoolId,
          gradeId: item.gradeId
        }
        classes.push(JSON.stringify(obj))
      })
      this.setState({
        teacherDetail: data,
        classes
      })
    })
  }
  handleCreate = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleSaveTeacherInfo(values)
      }
    })
  }
  handleCancle = () => {
    this.props.history.push('/teacherList')
  }
  handleSaveTeacherInfo = (values) => {
    const { teacherDetail, isEdit } = this.state
    const classList = []
    values.classes.map((item, index) => {
      let obj = JSON.parse(item)
      classList.push(obj)
    })
    let params = {
      teacherId: isEdit ? teacherDetail.teacherId : '',
      schoolId: getStore('schoolId'),
      name: values.name,
      birth: values.birth,
      sex: values.sex,
      phone: values.phone,
      post: '老师',
      classList
    }
    Api.saveTeacherInfo(params).then(({ res }) => {
      const { success } = res
      if (success) {
        message.success('操作成功')
        this.props.history.push('/teacherList')
      }
    })
  }
  handleSetData = (data) => {
    let lists = []
    data.forEach((item, index) => {
      if (item.classList.length > 0) {
        item.classList.map((el, ind) => {
          let idObj = {
            classId: el.classId,
            schoolId: el.schoolId,
            gradeId: el.gradeId
          }
          let obj = {
            name: `${el.gradeName} ${el.name}`,
            id: JSON.stringify(idObj),
          }
          lists.push(obj)
          this.setState({
            classList: lists
          })
        })
      }
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 12
      },
    }
    const { getFieldDecorator } = this.props.form
    const { teacherDetail, isEdit, classList, classes } = this.state
    return (
      <div style={{ paddingTop: '20px' }}>
        <Form>
          <FormItem label='姓名' {...formItemLayout}>
            {
              getFieldDecorator('name', {
                initialValue: teacherDetail.name,
                rules: [
                  { required: true, message: '请输入老师姓名!' }
                ]
              })(
                <Input placeholder='请输入老师姓名' maxLength={10} autoComplete='off' />
              )
            }
          </FormItem>
          <Form.Item label='性别' {...formItemLayout}>
            {getFieldDecorator('sex', {
              initialValue: teacherDetail.sex || '1',
            })(
              <Radio.Group>
                <Radio value='1'>男</Radio>
                <Radio value='2'>女</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <FormItem {...formItemLayout} label='出生日期'>
            {getFieldDecorator('birth', {
              initialValue: teacherDetail.birth ? moment(teacherDetail.birth, 'YYYY-MM-DD') : null,
            })(
              <DatePicker
                showTime
                format='YYYY-MM-DD'
                placeholder='请选择出生日期'
              />
            )}
          </FormItem>
          <FormItem label='手机号' {...formItemLayout}>
            {
              getFieldDecorator('phone', {
                initialValue: teacherDetail.phone,
                rules: [
                  { required: true, message: '请输入老师手机号!' }
                ]
              })(
                <Input placeholder='请输入老师手机号' maxLength={11} autoComplete='off' />
              )
            }
          </FormItem>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6} />
            <span>
              <span style={{ color: '#f5222d', marginRight: '5px' }}>*</span>
              手机号将作为钦家智能考勤老师端登录账号使用
            </span>
          </Row>

          <Form.Item label='负责班级' {...formItemLayout}>
            {getFieldDecorator('classes', {
              initialValue: classes,
              rules: [{ required: true, message: '请选择老师负责的班级!' }]
            })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  {classList.length > 0
                    ? classList.map((item, index) => {
                      return (
                        <Col span={8} key={index}>
                          <Checkbox value={item.id}>{item.name}</Checkbox>
                        </Col>
                      )
                    })
                    : null}
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6} />
            <span>
              <span style={{ color: '#f5222d', marginRight: '5px' }}>*</span>
              如果所负责的班级不在列表中，请前往班级管理中创建
            </span>
          </Row>
        </Form>
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <Button
            type='primary'
            onClick={this.handleCreate}
            style={{ marginRight: '20px' }}
          >
            {isEdit ? '修改' : '创建'}
          </Button>
          <Button onClick={this.handleCancle}>取消</Button>
        </div>
      </div>
    )
  }
}
AddTeacherForm.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object.isRequired
}

const AddTeacher = Form.create()(AddTeacherForm)

export default AddTeacher
