import React from 'react'
import { Route } from 'react-router-dom'
import GateSecurity from './gateSecuritySys'
import ParentsIdentity from './parents'
// console.log("========", ParentsIdentity);
// export default (
// 	<React.Fragment>
// 		<Route path="/parentsIdentity" component={ParentsIdentity} />
// 		<Route path="/gateSecurity" component={AddStudentInfo} />
// 	</React.Fragment>
// );

export default (
  <React.Fragment>
    <Route path="/parentsIdentity" component={ParentsIdentity}/>
    <Route path="/gateSecurity" component={GateSecurity}/>
  </React.Fragment>
)
